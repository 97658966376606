import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const ManagementServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

            <HomeTwoHeader />
            <ServicesDetailsHeader
                title="Management Services"
                subtitle="At BIYEM GLOBAL SERVICES LTD, our Management Services are crafted to optimize operations and drive strategic success."
            />

            <section className="services__details pt-115 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="services__text">
                                <p>
                                    Elevate your organization with our Management Services, designed to optimize
                                    operations and drive strategic success. From innovative solutions to strategic
                                    planning, we are committed to delivering excellence at every step.
                                </p>
                                <p>
                                    At Biyem Global, we understand the dynamic nature of the business landscape.
                                    Whether you are aiming for operational efficiency or strategic transformation, our
                                    tailored Management Services are geared to meet the evolving demands of your
                                    industry.
                                </p>
                            </div>

                            <div className="services__text">
                                <h3>Key Offerings</h3>
                                <p>
                                    Explore the comprehensive range of Management Services offered by BIYEM GLOBAL
                                    SERVICES LTD, designed to optimize and enhance your organizational processes:
                                </p>
                            </div>
                            <div className="services__list mb-40">
                                <ul>
                                    <li>
                                        <strong>Strategic Planning:</strong> Craft and implement strategic plans to
                                        drive organizational success and growth.
                                    </li>
                                    <li>
                                        <strong>Operational Optimization:</strong> Enhance operational efficiency
                                        through streamlined processes and innovative solutions.
                                    </li>
                                    <li>
                                        <strong>Change Management:</strong> Navigate organizational change with
                                        resilience and strategic foresight.
                                    </li>
                                    <li>
                                        <strong>Leadership Development:</strong> Foster leadership excellence through
                                        customized development programs.
                                    </li>
                                </ul>
                            </div>

                            <div className="services__text">
                                <h3>Benefits of Choosing BIYEM GLOBAL SERVICES LTD</h3>
                                <p>
                                    When you partner with BIYEM GLOBAL SERVICES LTD for your management needs, you're
                                    embracing a myriad of advantages tailored to elevate your organizational
                                    effectiveness:
                                </p>
                            </div>
                            <div className="services__list mb-40">
                                <ul>
                                    <li>
                                        <strong>Expertise:</strong> Our team consists of industry experts with a
                                        proven track record in strategic management.
                                    </li>
                                    <li>
                                        <strong>Innovation:</strong> BIYEM GLOBAL SERVICES LTD is committed to staying
                                        at the forefront of organizational advancements, embracing innovative
                                        solutions.
                                    </li>
                                    <li>
                                        <strong>Custom Solutions:</strong> Tailoring our Management Services to
                                        provide personalized solutions that align with your organizational goals.
                                    </li>
                                    <li>
                                        <strong>Reliability:</strong> Known for timely and reliable delivery, we
                                        understand the importance of keeping your organization on the path to success.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeTwoFooter />
        </>
    );
};

export default ManagementServices;

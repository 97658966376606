import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaVimeoV } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const HomeTwoFooter = () => {
   return (
      <>
         <footer>
            <div className="footer__area grey-bg pt-100">
               <div className="footer__top pb-45">
                  <div className="container">
                     <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title mb-30">
                                 <div className="logo">
                                    <Link to="/">
                                       <img style={{height:"140px", width:"200px"}} src="assets/img/logo/BIYEM.jpg" alt=""/>
                                    </Link>
                                 </div>
                              </div>
                              <div className="footer__widget-content">
                                 <p className="mb-30">Copyright © 2024 BIYEM GLOBAL SERVICES, LTD. All rights reserved.</p>
                                 <div className="footer__social theme-social mb-30">
                                    <ul>
                                       {/* <li>
                                          <a href="#">
                                             <i ><FaFacebookF /></i>
                                             <i ><FaFacebookF /></i>
                                          </a>
                                       </li> */}
                                       <li>
                                          <a href="https://www.instagram.com/official_biyem?igsh=MWg0OXJydWprYm5tdg%3D%3D&utm_source=qr">
                                             <i ><FaInstagram /> </i>
                                             <i ><FaInstagram /> </i>
                                          </a>
                                       </li>
                                       {/* <li>
                                          <a href="#">
                                             <i ><FaVimeoV /> </i>
                                             <i ><FaVimeoV /> </i>
                                          </a>
                                       </li> */}
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title">
                                 <h4>Company</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                       <li><a href="/about">About Us</a></li>
                                      <li><a href="/our-values">Our Values</a></li>
                                       {/* <li><a href="#">Mission and Vision</a></li> */}
                                     
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 offset-xl-1">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title">
                                 <h4>Services</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__links">
                                    <ul>
                                
                                       <li><a href="/industrial-service">Industrial Services</a></li>
                                       <li><a href="/ict-service">ICT Services</a></li>
                                       <li><a href="/commercial-service">Commercial Services</a></li>
                                       <li><a href="/construction-service">Construction Services</a></li>
                                       <li><a href="/consultancy-service">Consultancy Services</a></li>
                                       <li><a href="/agricultural-service">Agricultural Services</a></li>
                                       <li><a href="/procurement-service">Procurement Services</a></li>
                                       <li><a href="/event-management">Event Management</a></li>
                                       <li><a href="/financial-advisory-services">Financial Advisory Services</a></li>
                                       <li><a href="/logistics-and-courier-services">Logistics & Courier Services</a></li>
                                       <li><a href="/leadership-development-service">Leadership and Development Services</a></li>
                                    </ul>
                               
                                 </div>
                              </div>
                           </div>
                        </div>
                        {/* <div className="col-xl-3 col-lg-3 col-md-5 col-sm-6">
                           <div className="footer__widget mb-45">
                              <div className="footer__widget-title">
                                 <h4>Newsletter</h4>
                              </div>
                              <div className="footer__widget-content">
                                 <div className="footer__subscribe">
                                    <p className="mb-30">Subscribe to out newsletter today to receive updates on the latest news</p>
                                    <div className="footer__subscribe-form p-relative">
                                 
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div> */}
                        <div class="col-xl-3 col-lg-3 col-md-5 col-sm-6">
                        <div className="footer__widget-title">
                                 <h4>Enquiries</h4>
                              </div>
                        <div class="row no-gutters">

<div style={{display:"flex", flexDirection:"row", }} class="col-sm-12 col-md-6 col-lg-12 col-xl-12">
<ul class="ftr_enquiry p-3">
<li class="ftr_enq_title"><p class="pb-2"><i class="fas fa-briefcase mr-1 fa-2x text-dark"></i>&nbsp;&nbsp;BUSINESS ENQUIRY</p></li>
<hr style={{color:"grey"}}/>
<li class="mb-2" itemprop="email"><i class="fas fa-envelope mr-2 fa-lg"></i>&nbsp;&nbsp;
<a class="f_15 clr_black fwt_600" href="mailto:info@biyemglobal.com">info@biyemglobal.com</a></li>

</ul>

</div>
<div style={{display:"flex", flexDirection:"row"}} class="col-sm-12 col-md-12 col-xl-12">

<ul class="ftr_enquiry p-3">
<li class="ftr_enq_title"><p class="pb-2"><i class="fas fa-briefcase mr-2 fa-2x text-dark"></i>&nbsp;&nbsp;HR ENQUIRY</p></li>
<hr style={{color:"grey"}}/>
<li class="mb-2" itemprop="email">
<a class="f_15 clr_black fwt_600" href="mailto:info@biyemglobal.com"><i class="fas fa-envelope mr-2 fa-lg"></i>&nbsp;&nbsp;info@biyemglobal.com</a></li>
</ul>
</div>
</div>

</div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default HomeTwoFooter;
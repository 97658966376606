import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar/Sidebar';
import useGlobalContext from '../../../hooks/useGlobalContext';
import Services from '../../Services/Services';

const HomeTwoHeader = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();
   return (
      <>
         <header>
            <div className="header__area p-relative header__transparent">
               <div id="header__sticky" className={stickyMenu ? 'sticky header__bottom header__bottom-2' : 'header__bottom header__bottom-2'}>
                  <div className="container">
                     <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                           <div className="logo">
                              <NavLink to="/">
                                 <img style={{height:"100px", width:"140px"}} src="assets/img/logo/crop-biyem.jpeg" alt="logo"/>
                              </NavLink>
                           </div>
                           <div className="logo-gradient">
                              <NavLink to="/">
                                 <img style={{height:"140px", width:"200px"}} src="assets/img/logo/BIYEM.jpg" alt="logo"/>
                              </NavLink>
                           </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-6 col-sm-6 col-6">
                           <div className="header__bottom-right d-flex justify-content-end align-items-center">
                            <div className="main-menu menu_wrapper_one">
                                 <nav id="mobile-menu">
                                    <ul>
                                       <li>
                                          <NavLink to="/">Company</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/about">About Us</NavLink></li>
                                             <li><NavLink to="/our-values">Our Values</NavLink></li>
                                             {/* <li><NavLink to="#">mission&vision</NavLink></li> */}
                                          
                                          </ul>
                                       </li>
                                 
                                       <li>
                                          <NavLink to="/services">Services</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/industrial-service">Industrial Services</NavLink></li>
                                            <li><NavLink to="/ict-service">ICT Services</NavLink></li> 
                                             <li><NavLink to="/commercial-service">Commercial Services</NavLink></li>
                                            <li><NavLink to="/construction-service">Construction Services</NavLink></li> 
                                             <li><NavLink to="/consultancy-service">Consultancy Services</NavLink></li>
                                            <li><NavLink to="/agricultural-service">Agricultural Services</NavLink></li> 
                                            <li><NavLink to="/procurement-service">Procurement Services</NavLink></li> 
                                            <li><NavLink to="/event-management">Event Management</NavLink></li> 
                                            <li><NavLink to="/financial-advisory-services">Financial Advisory Services</NavLink></li> 
                                            <li><NavLink to="/logistics-and-courier-services">Logistics & Courier Services</NavLink></li> 
                                            <li><NavLink to="/leadership-development-service">Leadership and Development Services</NavLink></li> 
                                          </ul>
                                       </li>
                                       <li><NavLink to="/portfolio">Portfolio </NavLink></li>
                                       <li><NavLink to="/careers">Careers </NavLink></li>
                                       {/* <li>
                                          <NavLink to="/portfolio">Insights</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/portfolio">portfolio</NavLink></li>
                                             <li><NavLink to="/portfolioDetails">portfolio Details</NavLink></li>
                                             <li><NavLink to="/team">team</NavLink></li>
                                             <li><NavLink to="/teamDetails">team Details</NavLink></li>
                                          </ul>
                                       </li> */}
                                       {/* <li>
                                          <NavLink to="/blogs">Blog</NavLink>
                                          <ul className="submenu">
                                             <li><NavLink to="/blogs">Blog</NavLink></li>
                                             <li><NavLink to="/blogDetails">Blog Details</NavLink></li>
                                          </ul>
                                       </li> */}
                                       {/* <li><NavLink to="/contact">Contact Us</NavLink></li> */}
                                    </ul>
                                 </nav>
                              </div>
                              <div className="header__btn d-none d-sm-block d-xl-block ml-50">
                                 <Link to="/contact" className="z-btn z-btn-white z-btn-white-2">Contact Us</Link>
                              </div>
                              <div onClick={handleShow} className="sidebar__menu d-lg-none">
                                 <div className="sidebar-toggle-btn" id="sidebar-toggle">
                                    <span className="line"></span>
                                    <span className="line"></span>
                                    <span className="line"></span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </header>


         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default HomeTwoHeader;
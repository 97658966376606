import React from 'react';
import SingleService from '../../../components/SingleService/SingleService';

const HomeServices = () => {
   return (
      <>
         <section className="services__area pt-115 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <div className="section__title section__title-3 text-center mb-90 wow fadeInUp" data-wow-delay=".2s">
                        <span>Our Services</span>
                        <h2>Explore the myriad services offered by Biyem Global, guiding you towards sustainable growth with tailored solutions for your unique needs and aspirations.</h2>
                     </div>
                  </div>
               </div>
               <div className="row">
    <SingleService linkr="industrial-service" icon="11" title="Industrial Services" subtitle="Our Industrial services include the business of manufacturing, packaging or preserving manufactured goods of all descriptions for sale." />
    <SingleService linkr="ict-service" icon="12" title="ICT Services" subtitle="Our Technological services include the business of operating all kinds of software and information technology, project monitoring, and technical services." />
    <SingleService linkr="commercial-service" icon="13" title="Commercial Services" subtitle="Our Commercial services encompass a diverse range of manufacturing and the production of various technological, industrial, and end-to-end services." />
    <SingleService linkr="construction-service" icon="14" title="Construction Services" subtitle="Our Construction services cover a broad spectrum, including the maintenance and construction of roads, bridges, and more." />
    <SingleService linkr="consultancy-service" icon="15" title="Consultancy Services" subtitle="Explore innovative solutions with our Consultancy Services, tailored to meet your business challenges." />
    <SingleService linkr="agricultural-service" icon="16" title="Agricultural Services" subtitle="Our Agricultural Services involve the business of both small and large-scale farmers on tree crop productions, dairy farms, manufacturing, and the production of livestock feeds, fertilizers, and farming implements." />
    <SingleService linkr="procurement-service" icon="17" title="Procurement Services" subtitle="Efficiently manage your supply chain with our Procurement Services, ensuring timely and cost-effective sourcing." />
    <SingleService linkr="event-management" icon="18" title="Event Management" subtitle="Create unforgettable experiences with our Event Management services, meticulously planned for your success." />
    <SingleService linkr="consultancy-service" icon="19" title="Training & Leadership Development" subtitle="Unlock your team's potential through our Training & Leadership Development programs, fostering growth and success." />
    <SingleService linkr="consultancy-service" icon="20" title="Management Services" subtitle="Our management services provide leadership and workplace strategy for peak performance across a broad spectrum of sectors, supplying organizations and individuals with innovative ideas." />
    <SingleService linkr="procurement-service" icon="21" title="Logistics & Courier Services" subtitle="Streamline your operations with our Logistics & Courier Services, ensuring swift and secure deliveries." />
    <SingleService linkr="consultancy-service" icon="22" title="Financial Advisory Services" subtitle="Navigate financial complexities with confidence through our comprehensive Financial Advisory Services." />
</div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;
import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const ConsultancyServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

<HomeTwoHeader />
<ServicesDetailsHeader title="Consultancy Service" subtitle="Unlock strategic insights with BIYEM GLOBAL SERVICES LTD's dynamic Consultancy Services. Our experienced consultants bring a wealth of knowledge to guide your business through challenges, opportunities, and transformative growth." />
          <section className="services__details pt-115 pb-100">
             <div className="container">
                <div className="row">
                   {/* <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                      <div className="services__sidebar mr-50">
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Categories</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__link">
                                  <ul>
                                     <li><Link to="/servicesDetails">Market Analysis</Link></li>
                                     <li><Link to="/servicesDetails"> Great Tests</Link></li>
                                     <li><Link to="/servicesDetails">Optimal Choice</Link></li>
                                     <li><Link to="/servicesDetails">SEO Optimized</Link></li>
                                     <li><Link to="/servicesDetails">Task Consulting</Link></li>
                                     <li><Link to="/servicesDetails">Great Advices</Link></li>
                                  </ul>
                               </div>
                            </div>
                         </div>
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Subscribe Now</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__form">
                                  <form>
                                     <input type="text" placeholder="Name"/>
                                        <input type="email" placeholder="Email"/>
                                           <button className="z-btn z-btn-3 w-100">Subscribe Now</button>
                                        </form>
                                     </div>
                               </div>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <FiDownload/> </i>Download docs</a>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <AiOutlineFilePdf/> </i>Characteristics</a>
                            </div>
                         </div>
                      </div> */}
                      <div className="col-xl-12 col-lg-12">
                         <div className="services__text">
                         <p>At BIYEM GLOBAL SERVICES LTD, we understand the importance of strategic decision-making in the ever-evolving business landscape. Our Consultancy Services are designed to empower your organization with the expertise needed to navigate complexities, drive innovation, and achieve sustainable success.</p>
                <p>Whether you are a startup seeking market entry strategies or an established enterprise aiming for operational excellence, our consultancy services are tailored to meet your unique needs.</p>
                    </div>
                         {/* <div className="services__img mb-45 w-img">
                            <img src="assets/img/services/details/services-01.jpg" alt=""/>
                         </div> */}
                         <div className="services__text">
                            <h3>Key Offerings</h3>
                            <p>Explore our comprehensive Consultancy Services designed to address a variety of business challenges and opportunities:</p>
                         </div>
                         <div className="services__list mb-40">
                         <ul>
                    <li>
                        <strong>Strategic Planning:</strong> Develop a roadmap for success with our strategic planning services. We work closely with your leadership team to define objectives, identify opportunities, and create actionable plans for sustainable growth.
                    </li>
                    <li>
                        <strong>Market Entry Strategies:</strong> Navigate new markets confidently with our market entry strategies. We conduct thorough market research, assess potential risks and opportunities, and guide you in formulating effective entry plans.
                    </li>
                    <li>
                        <strong>Operational Excellence:</strong> Streamline your operations for maximum efficiency. Our consultants analyze processes, identify bottlenecks, and implement strategies to enhance overall operational excellence and performance.
                    </li>
                    <li>
                        <strong>Organizational Development:</strong> Cultivate a dynamic and resilient organization. Our consultancy services cover talent management, leadership development, and organizational culture, fostering a workplace that thrives on innovation and collaboration.
                    </li>
                    <li>
                        <strong>Financial Advisory:</strong> Make informed financial decisions with our financial advisory services. We provide insights into budgeting, investment strategies, and risk management to ensure your financial health and sustainability.
                    </li>
                </ul>
                         </div>
                         <div className="services__text">
                         <h3>Benefits of Choosing BIYEM GLOBAL SERVICES LTD for Consultancy Services</h3>
                         <p>Partnering with BIYEM GLOBAL SERVICES LTD for consultancy services delivers a range of strategic advantages:</p>
                         </div>
                         <div className="services__list mb-40">
                        
                        
                         <ul>
                    <li>
                        <strong>Strategic Vision:</strong> Our consultancy services provide a strategic vision for your organization, aligning short-term and long-term objectives to drive sustained growth and success.
                    </li>
                    <li>
                        <strong>Market Expansion:</strong> Expand into new markets confidently with our market entry strategies, enabling your business to tap into new opportunities and broaden its commercial footprint.
                    </li>
                    <li>
                        <strong>Operational Efficiency:</strong> Achieve operational excellence through our analysis of processes and implementation of strategies that optimize efficiency, reduce costs, and enhance overall productivity.
                    </li>
                    <li>
                        <strong>Organizational Resilience:</strong> Foster a resilient organizational culture with our organizational development services, focusing on talent management, leadership development, and creating a workplace that adapts to change.
                    </li>
                    <li>
                        <strong>Financial Stability:</strong> Make informed financial decisions with our financial advisory services, ensuring your business remains financially stable, resilient to risks, and well-positioned for future growth.
                    </li>
                    <li>
                        <strong>Customized Solutions:</strong> Benefit from customized consultancy solutions tailored to your unique business needs. Our consultants work collaboratively with your team to provide personalized strategies and insights.
                    </li>
                </ul>
                         </div>
                         {/* <div className="services__text">
                            <h4 >Why gormless loo he lost his bottle wellies cup of
                               tea pardon me lost the plot naff what a plonker lurgy show off show off pick your nose and blow.
                            </h4>
                            <p>Horse play skive off I amongst bonnet hanky panky
                               Richard brown bread grub I, sloshed nice one lavatory A bit of how's your father easy peasy daft
                               jolly good is, spiffing golly gosh in my flat cup of char tomfoolery a vagabond James Bond. Mush
                               down the pub victoria sponge zonked cracking goal off his nut the little rotter bits and bobs
                               say starkers blower why I william give us a bell.</p>
                         </div> */}
                      </div>
                   </div>
                </div>
          </section>
          {/* <section className="service-contact">
                <h2>Get in Touch</h2>
                <p>Ready to embark on a transformative journey with BIYEM GLOBAL SERVICES LTD's ICT Services? Contact us today to schedule a consultation, and let's explore how our innovative solutions can drive your business forward into a digital future.</p>
                <Link to="/contact" className="cta-button">Contact Us</Link>
            </section> */}
          <HomeTwoFooter />
        </>
    );
};

export default ConsultancyServices;
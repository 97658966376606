import React from 'react';

import HomeTwoFooter from '../HomeTwo/HomeTwoFooter/HomeTwoFooter';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoHeader from '../HomeTwo/HomeTwoHeader/HomeTwoHeader';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import SingleService from '../../components/SingleService/SingleService';
import { FaCheck } from 'react-icons/fa';
import { CgArrowLongRight } from 'react-icons/cg';
import { Link } from 'react-router-dom';

const OurValues = () => {
   return (
      <>
             <PageHelmet pageTitle="Our Values Page" />

<HomeTwoHeader/>
<CommonPageHeader title="Our Values" subtitle="" />
         <section className="services__area pt-115 pb-80">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 col-lg-12 ">
                     <div className="section__title section__title-3 text-center mb-90 wow fadeInUp" data-wow-delay=".2s">
                        {/* <span>Our Values</span> */}
                        <h2>At BIYEM GLOBAL SERVICES LTD, our values form the foundation of everything we do. They guide our decisions, actions, and relationships, ensuring that we consistently deliver excellence and uphold the highest standards of integrity and innovation. </h2>
                     </div>
                  </div>
               </div>
               <div className='text-center mb-80'>
               <h2 style={{color:"#4636ff"}}>Our Core Values</h2>
               </div>
               <div className="row text-center">
               
           
    <SingleService icon="11" title="Integrity" subtitle="We conduct our business with the highest ethical standards, fostering trust and transparency in all our interactions." />
  
    <SingleService icon="12" title="Excellence" subtitle="We strive for excellence in everything we do, delivering quality services and products that exceed expectations." />
    <SingleService icon="13" title="Innovation" subtitle="We embrace innovation and continuously seek creative solutions to drive positive change in our industry and beyond." />
    <SingleService icon="14" title="Client-Centric Approach" subtitle="Our clients are at the center of everything we do. We listen, understand, and tailor our services to meet their unique needs and aspirations." />
    <SingleService icon="15" title="Collaboration" subtitle="We believe in the power of collaboration. By working together, both internally and with our clients, partners, and communities, we achieve collective success." />
    <SingleService icon="16" title="Sustainability" subtitle="We are committed to sustainable business practices that contribute positively to the environment, society, and the well-being of future generations." />
    <SingleService icon="17" title="Empowerment" subtitle="We empower our team members, clients, and partners to reach their full potential. By fostering a culture of continuous learning and personal growth, we contribute to the success and development of individuals and organizations." />
    <SingleService icon="18" title="Social Responsibility" subtitle="We are committed to being socially responsible corporate citizens. We actively contribute to the well-being of the communities in which we operate, supporting initiatives that promote education, sustainability, and community development." />
   </div>
            </div>
         </section>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img style={{borderRadius:"50px"}} src="assets/img/about/about-3.jpeg" alt=""/>
                           <div className="about__shape">
                              <img src="assets/img/about/red-shape.png" alt=""/>
                           </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           {/* <span>Who we are</span> */}
                           <h2>Living Our Values</h2>
                        </div>
                        <p>Our commitment to these values is not just a statement; it's a way of life at BIYEM GLOBAL SERVICES LTD. We incorporate these values into our daily operations, decision-making processes, and interactions with all stakeholders.</p>
                <p>From the boardroom to the field, our team is dedicated to upholding these values, ensuring that every project we undertake and every relationship we build reflects the principles that define us.</p>
                        {/* <div className="about__list">
                           <ul>
                              <li ><span><i > <FaCheck/> </i>Innovative ideas</span></li>
                              <li ><span><i > <FaCheck/> </i>Professional assistance</span></li>
                              <li ><span><i > <FaCheck/> </i>Financial advisory</span></li>
                           </ul>
                        </div> */}
                        <Link to="/services" className="z-btn " >What we do<i > <CgArrowLongRight /> </i></Link>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="faq__area p-relative pt-135 pb-120 grey-bg-12">
            <div className="faq__thumb" style={{ background: `url(assets/img/faq/faq-4.jpeg)`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 offset-xl-7 col-lg-6 offset-lg-6 col-md-7 offset-md-5">
                     <div className="faq__wrapper">
                        <div className="section__title section__title-3 mb-25 wow fadeInUp" data-wow-delay=".2s">
                           <span>Thinking</span>
                           <h2>Value-Driven Results</h2>
                        </div>

                        <p>Our values are not just ideals; they are the driving force behind the results we achieve. By staying true to our core principles, we create a positive impact on our clients, employees, communities, and the broader world.</p>
                <p>As we continue to grow and evolve, our commitment to these values remains unwavering. They inspire us to push boundaries, embrace challenges, and strive for excellence in all aspects of our business.</p>

                     </div>
                  </div>
               </div>
            </div>
         </section>
         <section className="cta__area pt-180 pb-155" style={{ background: `url(assets/img/cta/cta-bg-3.jpeg)`
            , backgroundPosition: 'center', backgroundSize: 'cover' }} >
            <div className="container p-relative">
               <div className="row">
                  <div className="col-xl-10 offset-xl-1">
                     <div className="cta__content text-center">
               
                        <h1 >Join Our Team</h1>
                        <p style={{color:"#f5f5f5",fontSize:"18px"}}>If you share our values and are passionate about making a difference, consider joining the BIYEM GLOBAL SERVICES LTD family.<br/> Explore our careers page to discover exciting opportunities to contribute to our mission and be a part of a values-driven organization.</p>
                        <br/>
                        <div className="cta__btn">
                           <Link to="/careers" className="z-btn z-btn-white mb-30">Work With Us</Link>
                           {/* <Link to="/contact" className="z-btn z-btn-transparent mb-30">Work with us</Link> */}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <HomeTwoFooter/>
      </>
   );
};

export default OurValues;
import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const EventManagement = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

<HomeTwoHeader />
<ServicesDetailsHeader title="Event Management" subtitle="Create unforgettable experiences with BIYEM GLOBAL SERVICES LTD's professional Event Management services. From meticulous planning to flawless execution, we bring creativity and precision to every event, ensuring a seamless and memorable occasion." />
          <section className="services__details pt-115 pb-100">
             <div className="container">
                <div className="row">
                   {/* <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                      <div className="services__sidebar mr-50">
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Categories</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__link">
                                  <ul>
                                     <li><Link to="/servicesDetails">Market Analysis</Link></li>
                                     <li><Link to="/servicesDetails"> Great Tests</Link></li>
                                     <li><Link to="/servicesDetails">Optimal Choice</Link></li>
                                     <li><Link to="/servicesDetails">SEO Optimized</Link></li>
                                     <li><Link to="/servicesDetails">Task Consulting</Link></li>
                                     <li><Link to="/servicesDetails">Great Advices</Link></li>
                                  </ul>
                               </div>
                            </div>
                         </div>
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Subscribe Now</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__form">
                                  <form>
                                     <input type="text" placeholder="Name"/>
                                        <input type="email" placeholder="Email"/>
                                           <button className="z-btn z-btn-3 w-100">Subscribe Now</button>
                                        </form>
                                     </div>
                               </div>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <FiDownload/> </i>Download docs</a>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <AiOutlineFilePdf/> </i>Characteristics</a>
                            </div>
                         </div>
                      </div> */}
                      <div className="col-xl-12 col-lg-12">
                         <div className="services__text">
                         <p>At BIYEM GLOBAL SERVICES LTD, we understand the transformative power of events. Our Event Management services are designed to elevate your events, whether it's a corporate conference, product launch, or special celebration. We specialize in turning your vision into reality, creating experiences that leave a lasting impact.</p>
                <p>From conceptualization to execution, we are your dedicated partners in orchestrating events that exceed expectations and leave a positive impression on attendees.</p>   </div>
                         {/* <div className="services__img mb-45 w-img">
                            <img src="assets/img/services/details/services-01.jpg" alt=""/>
                         </div> */}
                         <div className="services__text">
                            <h3>Key Offerings</h3>
                            <p>Explore our comprehensive Event Management services tailored to meet the unique needs of your events:</p>
                   </div>
                         <div className="services__list mb-40">
                         <ul>
                    <li>
                        <strong>Event Planning and Conceptualization:</strong> Collaborate with our experienced event planners to conceptualize and plan every detail of your event. From theme development to logistics, we ensure a cohesive and engaging experience.
                    </li>
                    <li>
                        <strong>Venue Selection and Setup:</strong> Choose from a curated list of venues suitable for your event, and let us handle the setup. We manage everything from stage design to seating arrangements, creating an inviting atmosphere for your guests.
                    </li>
                    <li>
                        <strong>Vendor Coordination:</strong> Benefit from our extensive network of vendors. We coordinate with caterers, decorators, audio-visual teams, and other vendors to ensure seamless collaboration and flawless execution.
                    </li>
                    <li>
                        <strong>On-Site Management:</strong> Relax and enjoy your event while we manage everything on-site. Our team oversees logistics, troubleshoots any issues, and ensures that the event runs smoothly from start to finish.
                    </li>
                    <li>
            <strong>Media & Technical Services:</strong> Elevate your event with our media and technical expertise. We offer professional photography, cinematography, live streaming, and state-of-the-art screens, lighting, and sound services for both physical and online events.
        </li>

                </ul>
                         </div>
                         <div className="services__text">
                         <h3>Benefits of Choosing BIYEM GLOBAL SERVICES LTD for Event Management</h3>
                         <p>Partnering with BIYEM GLOBAL SERVICES LTD for Event Management brings a host of benefits to make your events extraordinary:</p>
              </div>
                         <div className="services__list mb-40">
                        
                         <ul>
                    <li>
                        <strong>Creative Conceptualization:</strong> Our event planners bring creativity to the table, ensuring that your event has a unique and captivating concept that aligns with your goals and audience.
                    </li>
                    <li>
                        <strong>Efficient Planning:</strong> From venue selection to vendor coordination, our efficient planning ensures that every aspect of your event is well-organized, allowing you to focus on the content and experience.
                    </li>
                    <li>
                        <strong>Seamless Execution:</strong> Experience a flawless execution on the day of the event. Our on-site management team handles logistics, troubleshoots issues, and ensures that everything runs according to plan.
                    </li>
                    <li>
                        <strong>Time and Cost Savings:</strong> Our experience and vendor relationships translate into time and cost savings for you. We negotiate on your behalf and streamline the planning process for maximum efficiency.
                    </li>
                    <li>
                        <strong>Positive Guest Experience:</strong> Leave a lasting impression on your guests with an event that exceeds their expectations. Our attention to detail and dedication to excellence contribute to a positive and memorable experience.
                    </li>
                    <li>
                        <strong>Customized Solutions:</strong> Every event is unique, and our approach is tailored to your specific needs and objectives. We work closely with you to understand your vision and deliver a customized event solution.
                    </li>
                </ul>
                         </div>
                         {/* <div className="services__text">
                            <h4 >Why gormless loo he lost his bottle wellies cup of
                               tea pardon me lost the plot naff what a plonker lurgy show off show off pick your nose and blow.
                            </h4>
                            <p>Horse play skive off I amongst bonnet hanky panky
                               Richard brown bread grub I, sloshed nice one lavatory A bit of how's your father easy peasy daft
                               jolly good is, spiffing golly gosh in my flat cup of char tomfoolery a vagabond James Bond. Mush
                               down the pub victoria sponge zonked cracking goal off his nut the little rotter bits and bobs
                               say starkers blower why I william give us a bell.</p>
                         </div> */}
                      </div>
                   </div>
                </div>
          </section>
          {/* <section className="service-contact">
                <h2>Get in Touch</h2>
                <p>Ready to embark on a transformative journey with BIYEM GLOBAL SERVICES LTD's ICT Services? Contact us today to schedule a consultation, and let's explore how our innovative solutions can drive your business forward into a digital future.</p>
                <Link to="/contact" className="cta-button">Contact Us</Link>
            </section> */}
          <HomeTwoFooter />
        </>
    );
};

export default EventManagement;
import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const LeadershipDevelopmentServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

            <HomeTwoHeader />
            <ServicesDetailsHeader
                title="Training & Leadership Development Services"
                subtitle="Empower your organization with our comprehensive Training & Leadership Development Services. Cultivate effective leaders, foster a culture of continuous growth, and achieve unparalleled success."
            />

            <section className="services__details pt-115 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="services__text">
                                <p>
                                    In today's dynamic business landscape, strong leadership is essential for an
                                    organization's success. Our Leadership & Development Services at Biyem Global are
                                    meticulously designed to empower your organization with effective leadership
                                    skills, cultivate a growth-oriented culture, and propel you towards unparalleled
                                    success.
                                </p>
                                <p>
                                    We believe that investing in leadership and professional development is not just
                                    a strategy; it's a commitment to building a resilient, innovative, and
                                    high-performing organization.
                                </p>
                            </div>

                            <div className="services__text">
                                <h3>Key Offerings</h3>
                                <p>
                                    Explore the diverse and impactful Leadership & Development Services offered by
                                    BIYEM GLOBAL SERVICES LTD:
                                </p>
                            </div>
                            <div className="services__list mb-40">
                                <ul>
                                    <li>
                                        <strong>Leadership Training Programs:</strong> Our tailored programs focus on
                                        enhancing leadership skills, strategic thinking, and decision-making
                                        abilities.
                                    </li>
                                    <li>
                                        <strong>Team Building Workshops:</strong> Foster a culture of collaboration
                                        and synergy within your teams, leading to improved communication and
                                        heightened performance.
                                    </li>
                                    <li>
                                        <strong>Professional Development Plans:</strong> We design personalized
                                        development plans for individual team members, fostering continuous learning
                                        and skill enhancement.
                                    </li>
                                    <li>
                                        <strong>Executive Coaching Sessions:</strong> One-on-one coaching sessions
                                        with experienced coaches to maximize the potential of your executives,
                                        ensuring effective leadership at the highest levels.
                                    </li>
                                    <li>
                                        <strong>Change Management Strategies:</strong> Equip your leadership with
                                        effective change management strategies to navigate organizational shifts
                                        seamlessly.
                                    </li>
                                    <li>
                                        <strong>Succession Planning:</strong> Develop a robust pipeline of future
                                        leaders within your organization, ensuring continuity and stability.
                                    </li>
                                </ul>
                            </div>

                            <div className="services__text">
                                <h3>Benefits of Choosing BIYEM GLOBAL SERVICES LTD</h3>
                                <p>
                                    Partnering with BIYEM GLOBAL SERVICES LTD for Leadership & Development Services
                                    provides your organization with numerous advantages:
                                </p>
                            </div>
                            <div className="services__list mb-40">
                                <ul>
                                    <li>
                                        <strong>Customized Programs:</strong> We understand that each organization
                                        is unique. Our programs are customized to address the specific needs, goals,
                                        and challenges of your organization.
                                    </li>
                                    <li>
                                        <strong>Experienced Facilitators:</strong> Our team comprises seasoned
                                        facilitators, coaches, and industry experts with a proven track record in
                                        leadership development.
                                    </li>
                                    <li>
                                        <strong>Measurable Impact:</strong> We focus on implementing strategies that
                                        deliver measurable improvements in leadership effectiveness and overall team
                                        performance.
                                    </li>
                                    <li>
                                        <strong>Continuous Support:</strong> Our commitment extends beyond training
                                        sessions. We offer ongoing support to ensure the sustained growth and
                                        development of your organizational leadership.
                                    </li>
                                    <li>
                                        <strong>Cutting-edge Insights:</strong> Stay ahead of the curve with our
                                        services that incorporate the latest trends, industry insights, and
                                        innovations in leadership and professional development.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeTwoFooter />
        </>
    );
};

export default LeadershipDevelopmentServices;

import React from 'react';
import HomeTwoSingleSlide from '../../../components/HomeTwoSingleSlide/HomeTwoSingleSlide';
import Slider from "react-slick";

const HomeTwoHeroSlider = () => {
   // slider setting
   const settings = {
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      infinite: true,
      dots: false,
      fade: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
   };

    return (
        <>
          <section className="slider__area slider__area-2">

             <Slider className='slider-active' {...settings}>

                <HomeTwoSingleSlide link="services" slider_class="col-xl-7 offset-xl-6 col-lg-8 offset-lg-4 col-md-9 offset-md-3 col-sm-10 offset-sm-2" content_class="" attr_2={<h1 >Let us  <br /> help get the commercial satisfaction you deserve</h1>} btn_text="See what's New" />

                <HomeTwoSingleSlide link="portfolio" slider_class="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-9 offset-md-3 col-sm-10 offset-sm-2" content_class="slider__content-3 text-center" attr_2={<h1 >Business Management For Challenging Times</h1>} btn_text="Get a Free Quote" />

                <HomeTwoSingleSlide link="services" slider_class="col-xl-6" content_class="slider__content-4" attr_1={<span >Welcome To Biyem.</span>} attr_2={<h1 >Get more Impact Faster</h1>} attr_3="We deal in the buying, selling, supply, exporting and importing of all kinds of commercial goods, raw materials and services.
" btn_text="See what's New" />

             </Slider>

          </section>
        </>
    );
};

export default HomeTwoHeroSlider;
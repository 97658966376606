import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';
const partnerLogos = [
    { src: 'assets/img/brand/bypenth_logo.png', alt: 'Partner 1' },
    { src: 'assets/img/brand/josh_logo.png', alt: 'Partner 2' },
    { src: 'assets/img/brand/niply_logo.png', alt: 'Partner 3' },
    { src: 'assets/img/brand/sbm_logo.png', alt: 'Partner 4' },
    { src: 'assets/img/brand/avanalch_logo.png', alt: 'Partner 5' },
    // { src: 'path/to/logo6.png', alt: 'Partner 6' },
    // Add more logos as needed
];

const PartnerLogos = () => (
    <div className="partner-logos">
        {partnerLogos.map((logo, index) => (
            <div className="logo-item" key={index}>
                <img src={logo.src} alt={logo.alt} />
            </div>
        ))}
    </div>
);

const FinancialAdvisoryServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

            <HomeTwoHeader />
            <ServicesDetailsHeader
                title="Financial Advisory Services"
                subtitle="Unlock the full potential of your finances with BIYEM GLOBAL. Our Financial Advisory Services provide strategic insights, wealth management, and tailored solutions for a secure financial future."
            />

            <section className="services__details pt-115 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="services__text">
                                <p>
                                    BIYEM GLOBAL empowers your financial journey with our Financial Advisory
                                    Services. Whether you are an individual or a business, we offer strategic
                                    insights, wealth management, and tailored financial solutions to ensure a secure
                                    and prosperous financial future.
                                </p>
                                <p>
                                    Our team of financial experts is dedicated to unlocking the full potential of
                                    your finances, providing guidance that goes beyond numbers to align with your
                                    unique goals and aspirations.
                                </p>
                            </div>

                            <div className="services__text">
                                <h3>Key Offerings</h3>
                                <p>
                                    Explore the comprehensive Financial Advisory Services provided by BIYEM GLOBAL:
                                </p>
                            </div>
                            <div className="services__list mb-40">
                                <ul>
                                    <li>
                                        <strong>Strategic Financial Planning:</strong> Tailored plans to align your
                                        financial goals with actionable strategies for growth and security.
                                    </li>
                                    <li>
                                        <strong>Wealth Management:</strong> Personalized wealth management solutions
                                        to preserve and grow your assets over the long term.
                                    </li>
                                    <li>
                                        <strong>Investment Advisory:</strong> Informed investment strategies designed
                                        to optimize returns while managing risks effectively.
                                    </li>
                                    <li>
                                        <strong>Retirement Planning:</strong> Secure your retirement with customized
                                        plans that ensure financial stability during your golden years.
                                    </li>
                                    <li>
                                        <strong>Tax Advisory:</strong> Expert guidance on tax planning and compliance
                                        to minimize liabilities and maximize savings.
                                    </li>
                                    <li>
                                        <strong>Debt Management:</strong> Strategies to efficiently manage and reduce
                                        debt, ensuring a healthy financial profile.
                                    </li>
                                </ul>
                            </div>

                            <div className="services__text">
                                <h3>Benefits of Choosing BIYEM GLOBAL</h3>
                                <p>
                                    Partnering with BIYEM GLOBAL SERVICES LTD for Financial Advisory Services offers
                                    numerous advantages:
                                </p>
                            </div>
                            <div className="services__list mb-40">
                                <ul>
                                    <li>
                                        <strong>Personalized Approach:</strong> Our financial advisory services are
                                        tailored to your unique financial situation, goals, and risk tolerance.
                                    </li>
                                    <li>
                                        <strong>Expert Guidance:</strong> Access to a team of experienced financial
                                        advisors providing insights and strategies backed by industry expertise.
                                    </li>
                                    <li>
                                        <strong>Comprehensive Solutions:</strong> A one-stop-shop for a wide range of
                                        financial services, ensuring holistic management of your financial well-being.
                                    </li>
                                    <li>
                                        <strong>Objective Advice:</strong> Unbiased and objective recommendations to
                                        help you make informed decisions aligned with your best interests.
                                    </li>
                                    <li>
                                        <strong>Continuous Monitoring:</strong> Regular monitoring and adjustment of
                                        your financial plan to adapt to changes in your life and the economic
                                        landscape.
                                    </li>
                                    <li>
                                        <strong>Peace of Mind:</strong> Experience peace of mind knowing that your
                                        financial future is in capable hands, with a focus on security and growth.
                                    </li>
                                </ul>
                            </div>
                            <div className="services__text">
                                    <h3>Our Trusted Partners</h3>
                                    <PartnerLogos />
                                </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeTwoFooter />
        </>
    );
};

export default FinancialAdvisoryServices;

import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const ConstructionServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

<HomeTwoHeader />
<ServicesDetailsHeader title="Construction Service" subtitle="Build for the future with BIYEM GLOBAL SERVICES LTD's comprehensive Construction Services. From infrastructure development to project management, we are your trusted partner in constructing a solid foundation for success." />
          <section className="services__details pt-115 pb-100">
             <div className="container">
                <div className="row">
                   {/* <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                      <div className="services__sidebar mr-50">
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Categories</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__link">
                                  <ul>
                                     <li><Link to="/servicesDetails">Market Analysis</Link></li>
                                     <li><Link to="/servicesDetails"> Great Tests</Link></li>
                                     <li><Link to="/servicesDetails">Optimal Choice</Link></li>
                                     <li><Link to="/servicesDetails">SEO Optimized</Link></li>
                                     <li><Link to="/servicesDetails">Task Consulting</Link></li>
                                     <li><Link to="/servicesDetails">Great Advices</Link></li>
                                  </ul>
                               </div>
                            </div>
                         </div>
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Subscribe Now</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__form">
                                  <form>
                                     <input type="text" placeholder="Name"/>
                                        <input type="email" placeholder="Email"/>
                                           <button className="z-btn z-btn-3 w-100">Subscribe Now</button>
                                        </form>
                                     </div>
                               </div>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <FiDownload/> </i>Download docs</a>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <AiOutlineFilePdf/> </i>Characteristics</a>
                            </div>
                         </div>
                      </div> */}
                      <div className="col-xl-12 col-lg-12">
                         <div className="services__text">
                         <p>At BIYEM GLOBAL SERVICES LTD, we recognize the pivotal role that construction plays in shaping the world around us. Our Construction Services are designed to deliver excellence in every aspect of the construction process, from conceptualization to execution.</p>
                <p>Whether you are embarking on a large-scale infrastructure project or require expertise in construction management, our team is dedicated to bringing your vision to reality.</p>
                         </div>
                         {/* <div className="services__img mb-45 w-img">
                            <img src="assets/img/services/details/services-01.jpg" alt=""/>
                         </div> */}
                         <div className="services__text">
                            <h3>Key Offerings</h3>
                            <p>Explore our comprehensive Construction Services designed to meet the diverse needs of modern construction projects:</p>
                         </div>
                         <div className="services__list mb-40">
                        
                         <ul>
                    <li>
                        <strong>Infrastructure Development:</strong> Transform your vision into reality with our expertise in infrastructure development. Our construction services cover a wide spectrum, including roads, bridges, and public facilities, ensuring lasting impact and functionality.
                    </li>
                    <li>
                        <strong>Project Management:</strong> Navigate the complexities of construction projects with our seasoned project management team. We oversee every aspect, from planning to execution, ensuring timelines, budgets, and quality standards are met.
                    </li>
                    <li>
                        <strong>Construction Consultancy:</strong> Benefit from strategic guidance with our construction consultancy services. We provide insights into regulatory compliance, feasibility studies, and cost-effective construction solutions tailored to your project.
                    </li>
                    <li>
                        <strong>Maintenance and Renovation:</strong> Ensure the longevity of your structures with our maintenance and renovation services. From routine inspections to extensive renovations, we enhance the durability and functionality of existing buildings.
                    </li>
                </ul>
                         </div>
                         <div className="services__text">
                         <h3>Benefits of Choosing BIYEM GLOBAL SERVICES LTD for Construction Services</h3>
                            <p>Partnering with BIYEM GLOBAL SERVICES LTD for your construction needs brings a range of benefits:</p>
                         </div>
                         <div className="services__list mb-40">
                        
                        
                         <ul>
                    <li>
                        <strong>Efficient Project Delivery:</strong> Our construction services prioritize efficient project delivery, ensuring that your construction projects are completed on time and within budget, minimizing disruptions and maximizing return on investment.
                    </li>
                    <li>
                        <strong>Quality Assurance:</strong> With a focus on excellence, we uphold the highest standards of quality assurance in construction. Your structures are built to last, meeting and exceeding industry regulations and expectations.
                    </li>
                    <li>
                        <strong>Strategic Consultancy:</strong> Leverage our construction consultancy services for strategic insights. We provide guidance on regulatory compliance, cost-effectiveness, and innovative solutions to optimize your construction projects.
                    </li>
                    <li>
                        <strong>Comprehensive Project Management:</strong> Our project management expertise ensures comprehensive oversight. From initial planning to final execution, we manage timelines, budgets, and quality standards, providing a seamless construction experience.
                    </li>
                    <li>
                        <strong>Sustainable Solutions:</strong> Embrace sustainable construction practices. We integrate eco-friendly materials and energy-efficient technologies to create structures that are not only resilient but also environmentally responsible.
                    </li>
                    <li>
                        <strong>Client-Centric Approach:</strong> Experience a client-centric approach with BIYEM GLOBAL SERVICES LTD. We prioritize clear communication, transparency, and collaboration, keeping you informed and involved throughout the construction process.
                    </li>
                </ul>
                         </div>
                         {/* <div className="services__text">
                            <h4 >Why gormless loo he lost his bottle wellies cup of
                               tea pardon me lost the plot naff what a plonker lurgy show off show off pick your nose and blow.
                            </h4>
                            <p>Horse play skive off I amongst bonnet hanky panky
                               Richard brown bread grub I, sloshed nice one lavatory A bit of how's your father easy peasy daft
                               jolly good is, spiffing golly gosh in my flat cup of char tomfoolery a vagabond James Bond. Mush
                               down the pub victoria sponge zonked cracking goal off his nut the little rotter bits and bobs
                               say starkers blower why I william give us a bell.</p>
                         </div> */}
                      </div>
                   </div>
                </div>
          </section>
          {/* <section className="service-contact">
                <h2>Get in Touch</h2>
                <p>Ready to embark on a transformative journey with BIYEM GLOBAL SERVICES LTD's ICT Services? Contact us today to schedule a consultation, and let's explore how our innovative solutions can drive your business forward into a digital future.</p>
                <Link to="/contact" className="cta-button">Contact Us</Link>
            </section> */}
          <HomeTwoFooter />
        </>
    );
};

export default ConstructionServices;
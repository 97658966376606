import React, { useState } from 'react';
import { AiOutlineReload } from 'react-icons/ai';
import SinglePortfolio from '../../../components/SinglePortfolio/SinglePortfolio';
import data from '../../../data/portfolioData';
import SinglePrice from '../../../components/SinglePrice/SinglePrice';
import ConstructionServices from '../../../components/Servicepages/ConstructionService';
import ConsultancyServices from '../../../components/Servicepages/ConsultancyService';
import Services from '../../Services/Services';
import ProcurementServices from '../../../components/Servicepages/ProcurementService';
import EventManagement from '../../../components/Servicepages/EventManagement';

// all category
const allCategory = ['all',...new Set(data.map(category => category.category))]
// array unique items
const arr = data;
const uniqueItem = arr.filter((arr, index, self) =>
   index === self.findIndex((t) => (t.img === arr.img && t.State === arr.State)))

const PortfolioArea = () => {
   // active btn
   const [active,setActive] = useState('all');
   const [filterPortfolio, setFilterPortfolio] = useState(uniqueItem);
   const [values,setValues] = useState(6);

   // filtering portfolio data
   const filterCategory = (category) => {
      setActive(category)
      if(category === 'all'){
         return setFilterPortfolio(uniqueItem)
      }
      const remainingItems = data.filter((item) => item.category === category);
      setFilterPortfolio(remainingItems)
   }
   // handleLoadMore
   const handleLoadMore = () => {
      setValues((value) => value + 1)
   }
   return (
      <>
         <section className="portfolio__area pt-110 pb-160">
            <div className="container">
               <div className="row">
                  <div className="col-xl-12 text-center">
                     <div className="portfolio__menu mb-40">
                        {/* <span>Filter by: </span> */}
                        <div className="masonary-menu filter-button-group d-sm-inline-block">
                           {
                              allCategory.map((btn,index) => {
                                 return <button onClick={()=> filterCategory(btn)} className={active === btn ? 'active text-capitalize' : 'text-capitalize'}
                                 key={index}>{btn}</button>
                              })
                           }
                        </div>
                     </div>
                  </div>
               </div>

               <div className='row'>
                  {
                     filterPortfolio.slice(0, values).map((portfolio,index) => <SinglePortfolio key={index} portfolio={portfolio}
                     filterPortfolio={filterPortfolio} index={index} />)
                  }
               </div>

               {values < filterPortfolio.length && <div className="row">
                  <div className="col-xl-2">
                     <div className="portfolio__load mt-25" onClick={handleLoadMore}>
                        <button className="z-btn z-btn-border">
                           <i><AiOutlineReload/></i> Load more</button>
                     </div>
                  </div>
               </div>}

            </div>

         </section>
         {/* <section style={{backgroundColor:"#4127b5"}} id="quote-section" name="quote-section" className="contact__area">
            <div className="container-fluid p-0">
               <div className="row g-0">
                  <div className="col-xl-12">
                     <div className="contact__map">
                        {/* <iframe title='contact' src="https://maps.google.com/maps?hl=en&amp;q=Abuja+()&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"></iframe> /}
                        <div className="contact__wrapper text-center wow fadeInUp" data-wow-delay=".3s">
                        <h3>Request a Quote</h3>
                           
                           <div className="contact__form">
                              <form >
                                 <div className='row'>
                                    <div className='col'>
                                    <input type="text" required placeholder="Your Name" />
                                    </div>
                                    <div className='col'>
                                    <input type="text" required placeholder="Company Name" />
                                
                                    </div>
                                 </div>
                                 <div className='row'>
                                    <div className='col'>
                                    <input type="text" required placeholder="Your Email" />
                                    </div>
                                    <div className='col'>
                                  
                                    <input type="tel" required placeholder="Phone Number" />
                                    </div>
                                 </div>
                                
                                 <div className='row'>
                                    <div className='col'>
                                    <input type="text" required placeholder="Country" />
                                    </div>
                                    <div className='col'>
                                  
                                    <select
                                      style={{height:"50px", borderRadius:"10px", padding:"0 15px", fontSize:"16px",border:"2px solid transparent", fontWeight:"500", backgroundColor:"#f5f5f5",width:"100%"}}
                                        class="selectpicker"

                                       //  onChange={(e) => setEventCategory(e.target.value)}
                                        multiple=""
                                        data-size="5"
                                        placeholder='Select Project Type'
                                        title="Select category"
                                        data-live-search="true"
                                      >
                                        <option value="select">Select Category</option>
                                    
                                        <option value="ICT Service">
                                        ICT Services
                                        </option>
                                        <option value="SPORTS">
                                         Industrial Services
                                        </option>
                                        <option value="GALA">
                                        Commercial Services
                                        </option>
                                        <option value="FASHION">
                                         Construction Services
                                        </option>
                                        <option value="FESTIVALS">
                                        Consultancy Services
                                        </option>
                                      
                                      
                                        <option value="FOODANDDRINKS">
                                    Agricultural Services
                                        </option>
                                        <option value="WEBINARS">
                               Procurement Services
                                        </option>
                                        <option value="TRAINING_WORKSHOP">
                                  Event Management
                                        </option>
                                        <option value="ONLINE_CLASS">
                                        Training & Leadership Development
                                        </option>
                                        <option value="TALK_SHOW">
                                        Management Services
                                        </option>
                                        <option value="Religion and Spirituality">
                                        Logistics & Courier Services
                                        </option>
                                        <option value="TECH">
                                        Financial Advisory Services
                                        </option>
                                
                                     
                                      </select>
                                    </div>
                                 </div>
                     
                                 <textarea required placeholder="Your Message"></textarea>
                                 <button
                                 //  type="submit" 
                                  className="z-btn">Send Message</button>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section> */}
      
      
      </>
   );
};

export default PortfolioArea;
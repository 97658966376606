import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';
import { BiMap } from 'react-icons/bi';
import { FaEnvelope, FaPhoneAlt, FaSearch } from 'react-icons/fa';

const Sidebar = ({ show, handleClose }) => {

   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  <div className="logo">
                     <a href="/">
                        <img style={{height:"100px", width:"140px"}}  src="assets/img/logo/crop-biyem.jpeg" alt="logo" />
                     </a>
                  </div>
               </Offcanvas.Header>

               <Offcanvas.Body>


                  <section>
                     <div className="p-0">
                        <div className="sidebar__tab">
                           <ul className="nav nav-tabs" id="sidebar-tab" role="tablist">
                              <li className="nav-item">
                                 <a className="nav-link active" id="menu-tab" data-bs-toggle="tab" href="#menu" role="tab" aria-controls="menu" aria-selected="true">menu</a>
                              </li>
                              <li className="nav-item">
                                 <a className="nav-link" id="info-tab" data-bs-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="false">info</a>
                              </li>
                           </ul>
                        </div>
                        <div className="sidebar__content">

                           <div className="tab-content" id="sidebar-tab-content">
                              <div className="tab-pane fade show active" id="menu" role="tabpanel" aria-labelledby="menu-tab">

                                 <div className='side_navBar'>
                                 <div className='about iconAdd'>
                                       <NavLink to="/">Home</NavLink>
                                   </div>
                          
                                   <Collapsible trigger={<NavLink to="/">Company</NavLink>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                       <ul className="sidebar_sub_menu submenu text-white text-capitalize">
                                       <li><NavLink to="/about">About Us</NavLink></li>
                                       <li><NavLink to="/our-values">Our Values</NavLink></li>
                                       </ul>
                                    </Collapsible>
                            

                                    <Collapsible trigger={<NavLink to="/services">Services</NavLink>} triggerTagName="div"
                                       triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                                          <ul className="sidebar_sub_menu submenu text-white">
                                          <li><NavLink to="/industrial-service">Industrial Services</NavLink></li>
                                            <li><NavLink to="/ict-service">ICT Services</NavLink></li> 
                                             <li><NavLink to="/commercial-service">Commercial Services</NavLink></li>
                                            <li><NavLink to="/construction-service">Construction Services</NavLink></li> 
                                             <li><NavLink to="/consultancy-service">Consultancy Services</NavLink></li>
                                            <li><NavLink to="/agricultural-service">Agricultural Services</NavLink></li> 
                                            <li><NavLink to="/procurement-service">Procurement Services</NavLink></li> 
                                            <li><NavLink to="/event-management">Event Management</NavLink></li> 
                                            <li><NavLink to="/financial-advisory-services">Financial Advisory Services</NavLink></li> 
                                            <li><NavLink to="/logistics-and-courier-services">Logistics & Courier Services</NavLink></li> 
                                            <li><NavLink to="/leadership-development-service">Leadership and Development Services</NavLink></li> 
                                          </ul>
                                    </Collapsible>

                                    <div className='about iconAdd'>
                                       <NavLink to="/portfolio">Portfolio</NavLink>
                                   </div>

                                   <div className='about iconAdd'>
                                       <NavLink to="/">Careers</NavLink>
                                   </div>

                                    <div className='about iconAdd border-0'>
                                       <NavLink to="/contact">Contact Us </NavLink>
                                    </div>

                                 </div>

                              </div>

                              <div className="tab-pane fade" id="info" role="tabpanel" aria-labelledby="info-tab">
                                 <div className="sidebar__info">
                                    {/* <div className="logo mb-40">
                                       <a href="index.html">
                                          <img src="assets/img/logo/logo.png" alt="logo"/>
                                       </a>
                                    </div> */}
                                    <p>We at BIYEM GLOBAL SERVICES LTD are more than just a simple business company, We offer a lifestyle to consumers and customers.</p>
                                    <a href="contact.html" className="z-btn z-btn-white">contact us</a>
                                    {/* <div className="sidebar__search">
                                       <form action="#">
                                          <input type="text" placeholder="Your Keywords.."/>
                                          <button type="submit"><i > <FaSearch /> </i></button>
                                       </form>
                                    </div> */}
                                    <div className="sidebar__contact mt-30">
                                       <ul>
                                          <li>
                                             <div className="icon">
                                                <i > <BiMap /> </i>
                                             </div>
                                             <div className="text">
                                                <span>Suite C101 Crowther Plaza, Gudu District, Abuja</span>
                                             </div>
                                          </li>
                                          <li>
                                             <div className="icon">
                                                <i > <FaEnvelope /> </i>
                                             </div>
                                             <div className="text ">
                                                <span><a href="mailto:info@biyemglobal.com">info@biyemglobal.com</a></span>
                                             </div>
                                          </li>
                                          <li>
                                             <div className="icon">
                                                <i ><FaPhoneAlt /> </i>
                                             </div>
                                             <div className="text">
                                                <span><a href="tel:+2347083268119">+2347083268119</a></span>
                                             </div>
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;
import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import Footer from '../../components/shared/Footer';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeOneHeader from '../Home/HomeOneHeader/HomeOneHeader';
import PortfolioArea from './PortfolioArea/PortfolioArea';
import HomeTwoHeader from '../HomeTwo/HomeTwoHeader/HomeTwoHeader';
import HomeTwoFooter from '../HomeTwo/HomeTwoFooter/HomeTwoFooter';

const Portfolio = () => {
   return (
      <>
         <PageHelmet pageTitle="Portfolio Page" />

         <HomeTwoHeader/>
         <CommonPageHeader title="Our Portfolio" subtitle="Introducing our diverse array of solutions, characterized by stringent quality standards." />
         <PortfolioArea/>
         <HomeTwoFooter/>
      </>
   );
};

export default Portfolio;
import React from 'react';
import { BiLock } from 'react-icons/bi';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaHandshake, FaLightbulb, FaRibbon, FaTractor } from 'react-icons/fa';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import HomeTwoSingleFeature from '../../../components/HomeTwoSingleFeature/HomeTwoSingleFeature';

const HomeTwoFeatures = () => {
   return (
      <>
         <section className="features__area pt-115 pb-120">
            <div className="container">
               <div className="row">
                  <div className="col-xl-5 col-lg-6">
                     <div className="features__content-left">
                        <div className="section__title section__title-h2 mb-25">
                           <span>Solutions For Every Business</span>
                           <h2>Improve <br/> your consumer centricity and agility </h2>
                        </div>
                        <p>Elevate Your Business: Enhance Consumer-Centric Strategies and Boost Agility for Unmatched Success in a Dynamic Market.</p>
                        <Link to="/about" className="z-btn">What we do<i><CgArrowLongRight/></i></Link>
                     </div>
                  </div>
                  <div className="col-xl-6 offset-xl-1 col-lg-6">
                     <div className="features__content-right">
                        <div className="row">
                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                              <HomeTwoSingleFeature icon={<FaLightbulb />} title="Technology services" link="ict-service" text="We carry out business involving information technology and ICT services, as designers, developers of all kinds of software/information technology ." />
                              <HomeTwoSingleFeature icon={<IoDocumentTextOutline />} title="Logistics & Courier Services" link="logi" text="Experience seamless supply chain management and reliable courier services with Biyem Global. We ensure efficient transportation, secure deliveries, and optimized logistics solutions for your business needs." />

                           </div>

                           <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">

                              <HomeTwoSingleFeature icon={<FaHandshake />} title="Management Services" link="consultancy-service" text="We carry out the business of developing projects and project management, and the business of management services in all aspects of the development sector." />
                              <HomeTwoSingleFeature icon={<FaTractor />} title="Agricultural Services" link="agricultural-service" text="Our Agricultural Services involve the business of both small and large scale farmers on tree crop productions, dairy farms, manufacturing and production of livestock feeds, fertilizers and farming implements." />

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoFeatures;
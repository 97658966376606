import React from 'react';
import { Link } from 'react-router-dom';

const SingleContact = ({ image, title, text, btn_text }) => {
   return (
      <div className="col-xl-5 col-lg-6 col-md-6 offset-xl-1 mb-4">
         <div className="card text-center shadow-sm border-0">
            <div className="card-body">
               {/* Contact Icon */}
               <div className="contact__icon mb-4">
                  <img
                     src={`assets/img/icon/contact/${image}.png`}
                     alt={title}
                     className="img-fluid"
                  />
               </div>

               {/* Title and Description */}
               <h3 className="h5 mb-3">{title}</h3>
               <p className="text-muted mb-4">{text}</p>

               {/* Contact Info List */}
               <ul className="list-unstyled mb-4">
                  <li className="mb-3">
                     <h5 className="h6">Address</h5>
                     <p className="mb-0">Suite C101 Crowther Plaza, Gudu District, Abuja</p>
                  </li>
                  <li className="mb-3">
                     <h5 className="h6">Call Us</h5>
                     <p className="mb-0">
                        <a href="tel:+2347083268119" className="text-decoration-none text-primary">
                           +2347083268119
                        </a>
                     </p>
                  </li>
                  <li>
                     <h5 className="h6">Email Address</h5>
                     <p className="mb-0">
                        <a href="mailto:info@biyemglobal.com" className="text-decoration-none text-primary">
                           info@biyemglobal.com
                        </a>
                     </p>
                  </li>
               </ul>

               {/* Button */}
               {/* <a href="info@biyemglobal.com" className="z-btn z-btn-border">{btn_text}</a> */}
            </div>
         </div>
      </div>
   );
};

export default SingleContact;

import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const CommercialServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

<HomeTwoHeader />
<ServicesDetailsHeader title="Commercial Service" subtitle="Experience the pinnacle of business excellence with BIYEM GLOBAL SERVICES LTD's comprehensive Commercial Services. Our offerings span a wide spectrum of manufacturing, production, and end-to-end services, tailored to meet the diverse needs of modern commerce." />
          <section className="services__details pt-115 pb-100">
             <div className="container">
                <div className="row">
                   {/* <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                      <div className="services__sidebar mr-50">
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Categories</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__link">
                                  <ul>
                                     <li><Link to="/servicesDetails">Market Analysis</Link></li>
                                     <li><Link to="/servicesDetails"> Great Tests</Link></li>
                                     <li><Link to="/servicesDetails">Optimal Choice</Link></li>
                                     <li><Link to="/servicesDetails">SEO Optimized</Link></li>
                                     <li><Link to="/servicesDetails">Task Consulting</Link></li>
                                     <li><Link to="/servicesDetails">Great Advices</Link></li>
                                  </ul>
                               </div>
                            </div>
                         </div>
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Subscribe Now</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__form">
                                  <form>
                                     <input type="text" placeholder="Name"/>
                                        <input type="email" placeholder="Email"/>
                                           <button className="z-btn z-btn-3 w-100">Subscribe Now</button>
                                        </form>
                                     </div>
                               </div>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <FiDownload/> </i>Download docs</a>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <AiOutlineFilePdf/> </i>Characteristics</a>
                            </div>
                         </div>
                      </div> */}
                      <div className="col-xl-12 col-lg-12">
                         <div className="services__text">
                         <p>At BIYEM GLOBAL SERVICES LTD, we understand that commercial success requires a combination of innovation, efficiency, and strategic positioning. Our Commercial Services are designed to provide a robust foundation for businesses, encompassing various aspects of manufacturing, production, and service delivery.</p>
                <p>Whether you are a small-scale enterprise or a large corporation, our tailored solutions are geared to optimize your commercial operations.</p>
                         </div>
                         {/* <div className="services__img mb-45 w-img">
                            <img src="assets/img/services/details/services-01.jpg" alt=""/>
                         </div> */}
                         <div className="services__text">
                            <h3>Key Offerings</h3>
                            <p>Explore our comprehensive suite of Commercial services designed to address the intricate needs of modern businesses:</p>
                         </div>
                         <div className="services__list mb-40">
                        
                         <ul>
                    <li>
                        <strong>Manufacturing Excellence:</strong> Elevate your manufacturing processes with our commitment to precision, quality control, and innovation. We specialize in optimizing production workflows to maximize output efficiency while maintaining the highest quality standards.
                    </li>
                    <li>
                        <strong>Production Variety:</strong> Our Commercial Services encompass a wide array of products and services, including technological, industrial, and end-to-end solutions. We bring a versatile approach to meet the dynamic needs of our clients across different sectors.
                    </li>
                    <li>
                        <strong>Supply Chain Management:</strong> Optimize your supply chain for seamless operations. Our services include end-to-end supply chain analysis, logistics optimization, and strategic management, ensuring a streamlined and efficient flow of goods and services from production to delivery.
                    </li>
                    <li>
                        <strong>Market Expansion:</strong> Explore new markets and enhance your commercial footprint with our strategic guidance. We provide comprehensive market research, entry strategies, and business development services to help you expand your reach and capitalize on untapped opportunities.
                    </li>
                    <li>
                        <strong>Commercial Analytics:</strong> Leverage data-driven insights for strategic decision-making. Our analytics services enable you to make informed choices regarding market trends, consumer behavior, and competitive positioning, empowering your business to stay ahead.
                    </li>
                    <li>
                        <strong>Strategic Partnerships:</strong> Forge impactful collaborations with our support in establishing strategic partnerships. We facilitate connections, negotiations, and alliances that contribute to your commercial success and growth.
                    </li>
                </ul>
                         </div>
                         <div className="services__text">
                            <h3>Benefits of Choosing BIYEM GLOBAL SERVICES LTD for Commercial Services</h3>
                            <p>Partnering with BIYEM GLOBAL SERVICES LTD for your Commercial services unlocks a plethora of advantages:</p>
                         </div>
                         <div className="services__list mb-40">
                        
                        
                         <ul>
                    <li>
                        <strong>Operational Efficiency:</strong> Experience heightened operational efficiency through our streamlined and optimized commercial solutions, ensuring that your business processes are agile and cost-effective.
                    </li>
                    <li>
                        <strong>Quality Assurance:</strong> Our commitment to manufacturing excellence guarantees that your products and services meet and exceed industry standards, instilling confidence in your customers and stakeholders.
                    </li>
                    <li>
                        <strong>Market Adaptability:</strong> Stay adaptable in the dynamic commercial landscape. Our solutions are designed to flexibly respond to market changes, allowing your business to stay ahead of trends and customer demands.
                    </li>
                    <li>
                        <strong>Strategic Growth:</strong> Leverage our strategic guidance for market expansion. We not only provide insights and plans but actively support the execution of strategies that fuel your business growth into new markets and sectors.
                    </li>
                    <li>
                        <strong>Data-Driven Decisions:</strong> Harness the power of commercial analytics for informed decision-making. Our analytics services empower you with actionable insights, enabling you to make data-driven choices that impact your bottom line positively.
                    </li>
                    <li>
                        <strong>Long-Term Partnerships:</strong> Beyond service delivery, we are invested in building enduring partnerships. Collaborate with us to establish and nurture strategic alliances that contribute to sustained commercial success.
                    </li>
                    <li>
                        <strong>Innovation Integration:</strong> Stay at the forefront of innovation with our focus on integrating the latest technologies and strategies into your commercial operations, ensuring you remain competitive and forward-thinking.
                    </li>
                </ul>
                         </div>
                         {/* <div className="services__text">
                            <h4 >Why gormless loo he lost his bottle wellies cup of
                               tea pardon me lost the plot naff what a plonker lurgy show off show off pick your nose and blow.
                            </h4>
                            <p>Horse play skive off I amongst bonnet hanky panky
                               Richard brown bread grub I, sloshed nice one lavatory A bit of how's your father easy peasy daft
                               jolly good is, spiffing golly gosh in my flat cup of char tomfoolery a vagabond James Bond. Mush
                               down the pub victoria sponge zonked cracking goal off his nut the little rotter bits and bobs
                               say starkers blower why I william give us a bell.</p>
                         </div> */}
                      </div>
                   </div>
                </div>
          </section>
          {/* <section className="service-contact">
                <h2>Get in Touch</h2>
                <p>Ready to embark on a transformative journey with BIYEM GLOBAL SERVICES LTD's ICT Services? Contact us today to schedule a consultation, and let's explore how our innovative solutions can drive your business forward into a digital future.</p>
                <Link to="/contact" className="cta-button">Contact Us</Link>
            </section> */}
          <HomeTwoFooter />
        </>
    );
};

export default CommercialServices;
import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const IctServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

<HomeTwoHeader />
<ServicesDetailsHeader title="Ict Service" subtitle="Empower your business with BIYEM GLOBAL SERVICES LTD's transformative Information and Communication Technology (ICT) services. From strategic consulting to seamless implementation, we are committed to revolutionizing the way you leverage technology for business success." />
          <section className="services__details pt-115 pb-100">
             <div className="container">
                <div className="row">
                   {/* <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                      <div className="services__sidebar mr-50">
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Categories</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__link">
                                  <ul>
                                     <li><Link to="/servicesDetails">Market Analysis</Link></li>
                                     <li><Link to="/servicesDetails"> Great Tests</Link></li>
                                     <li><Link to="/servicesDetails">Optimal Choice</Link></li>
                                     <li><Link to="/servicesDetails">SEO Optimized</Link></li>
                                     <li><Link to="/servicesDetails">Task Consulting</Link></li>
                                     <li><Link to="/servicesDetails">Great Advices</Link></li>
                                  </ul>
                               </div>
                            </div>
                         </div>
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Subscribe Now</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__form">
                                  <form>
                                     <input type="text" placeholder="Name"/>
                                        <input type="email" placeholder="Email"/>
                                           <button className="z-btn z-btn-3 w-100">Subscribe Now</button>
                                        </form>
                                     </div>
                               </div>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <FiDownload/> </i>Download docs</a>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <AiOutlineFilePdf/> </i>Characteristics</a>
                            </div>
                         </div>
                      </div> */}
                      <div className="col-xl-12 col-lg-12">
                         <div className="services__text">
                         <p>At BIYEM GLOBAL SERVICES LTD, we view Information and Communication Technology as a cornerstone for business growth. Our holistic approach to ICT services encompasses strategic planning, cutting-edge solutions, and ongoing support, ensuring that your business remains at the forefront of technological innovation.</p>
                <p>As your strategic ICT partner, we navigate the complexities of the digital landscape, tailoring our services to suit your unique business objectives.</p>
                         </div>
                         {/* <div className="services__img mb-45 w-img">
                            <img src="assets/img/services/details/services-01.jpg" alt=""/>
                         </div> */}
                         <div className="services__text">
                            <h3>Key Offerings</h3>
                            <p>Explore our comprehensive suite of ICT services designed to address the intricate needs of modern businesses:</p>
                         </div>
                         <div className="services__list mb-40">
                        
                         <ul>
                    <li>
                        <strong>Software Development:</strong> From custom applications to scalable software solutions, our development team crafts digital experiences that align with your business goals and user expectations.
                    </li>
                    <li>
                        <strong>Information Technology Management:</strong> Ensure the robustness of your IT infrastructure with our end-to-end management services. We cover everything from network optimization to cybersecurity, ensuring a secure and efficient digital environment.
                    </li>
                    <li>
                        <strong>Project Monitoring:</strong> Monitor and optimize project performance with our advanced monitoring solutions. Real-time analytics empower you to make informed decisions, enhancing project outcomes.
                    </li>
                    <li>
                        <strong>Technical Services:</strong> Rely on our responsive technical support for seamless operations. We provide troubleshooting, upgrades, and maintenance to keep your ICT systems running at peak performance.
                    </li>
                    <li>
                        <strong>Data Analytics:</strong> Unlock the hidden potential of your data. Our analytics services offer actionable insights, empowering data-driven decision-making for sustained business growth.
                    </li>
                    <li>
                        <strong>Cloud Solutions:</strong> Embrace the agility of cloud computing. Our cloud solutions optimize resource utilization, enhance collaboration, and provide scalable infrastructure tailored to your business needs.
                    </li>
                    <li>
                        <strong>ICT Consultancy:</strong> Benefit from strategic guidance with our ICT consultancy services. We work closely with your team to develop a roadmap for technology adoption, ensuring alignment with business objectives.
                    </li>
                </ul>
                         </div>
                         <div className="services__text">
                            <h3>Benefits of Choosing BIYEM GLOBAL SERVICES LTD for ICT Services </h3>
                            <p>Partnering with BIYEM GLOBAL SERVICES LTD for your ICT needs unlocks a plethora of advantages:</p>
                         </div>
                         <div className="services__list mb-40">
                        
                        
                         <ul>
                    <li>
                        <strong>Strategic Innovation:</strong> Our ICT solutions are strategically aligned with your business objectives, ensuring that technology becomes a catalyst for innovation and sustained growth.
                    </li>
                    <li>
                        <strong>Customized Solutions:</strong> Recognizing the uniqueness of each business, we deliver personalized ICT solutions tailored to your specific needs and industry nuances.
                    </li>
                    <li>
                        <strong>Reliable Support:</strong> Our dedicated support team ensures the reliability and performance of your ICT systems, providing timely assistance and proactive maintenance.
                    </li>
                    <li>
                        <strong>Scalability:</strong> Our solutions are designed to scale seamlessly with your business. Whether you're a startup or an enterprise, our ICT services adapt to your evolving needs.
                    </li>
                    <li>
                        <strong>Strategic Partnerships:</strong> Beyond service delivery, we foster long-term partnerships. We work closely with clients, offering strategic guidance and adapting our services to contribute to your overall success.
                    </li>
                    <li>
                        <strong>Continuous Innovation:</strong> Stay ahead of the technological curve with our commitment to continuous innovation. We proactively explore emerging technologies to keep your business at the forefront of digital transformation.
                    </li>
                </ul>
                         </div>
                         {/* <div className="services__text">
                            <h4 >Why gormless loo he lost his bottle wellies cup of
                               tea pardon me lost the plot naff what a plonker lurgy show off show off pick your nose and blow.
                            </h4>
                            <p>Horse play skive off I amongst bonnet hanky panky
                               Richard brown bread grub I, sloshed nice one lavatory A bit of how's your father easy peasy daft
                               jolly good is, spiffing golly gosh in my flat cup of char tomfoolery a vagabond James Bond. Mush
                               down the pub victoria sponge zonked cracking goal off his nut the little rotter bits and bobs
                               say starkers blower why I william give us a bell.</p>
                         </div> */}
                      </div>
                   </div>
                </div>
          </section>
          {/* <section className="service-contact">
                <h2>Get in Touch</h2>
                <p>Ready to embark on a transformative journey with BIYEM GLOBAL SERVICES LTD's ICT Services? Contact us today to schedule a consultation, and let's explore how our innovative solutions can drive your business forward into a digital future.</p>
                <Link to="/contact" className="cta-button">Contact Us</Link>
            </section> */}
          <HomeTwoFooter />
        </>
    );
};

export default IctServices;
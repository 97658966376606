import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const IndustrialServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

<HomeTwoHeader />
<ServicesDetailsHeader title="Industrial Service" subtitle="At BIYEM GLOBAL SERVICES LTD, our Industrial Services are meticulously designed to elevate manufacturing processes, ensuring efficiency, quality, and innovation." />
          <section className="services__details pt-115 pb-100">
             <div className="container">
                <div className="row">
                   {/* <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                      <div className="services__sidebar mr-50">
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Categories</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__link">
                                  <ul>
                                     <li><Link to="/servicesDetails">Market Analysis</Link></li>
                                     <li><Link to="/servicesDetails"> Great Tests</Link></li>
                                     <li><Link to="/servicesDetails">Optimal Choice</Link></li>
                                     <li><Link to="/servicesDetails">SEO Optimized</Link></li>
                                     <li><Link to="/servicesDetails">Task Consulting</Link></li>
                                     <li><Link to="/servicesDetails">Great Advices</Link></li>
                                  </ul>
                               </div>
                            </div>
                         </div>
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Subscribe Now</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__form">
                                  <form>
                                     <input type="text" placeholder="Name"/>
                                        <input type="email" placeholder="Email"/>
                                           <button className="z-btn z-btn-3 w-100">Subscribe Now</button>
                                        </form>
                                     </div>
                               </div>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <FiDownload/> </i>Download docs</a>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <AiOutlineFilePdf/> </i>Characteristics</a>
                            </div>
                         </div>
                      </div> */}
                      <div className="col-xl-12 col-lg-12">
                         <div className="services__text">
                         <p>Our Industrial Services encompass a comprehensive range of solutions aimed at enhancing the production lifecycle. From cutting-edge manufacturing techniques to sustainable packaging and preservation methods, we are committed to delivering excellence at every step.</p>
                <p>At Biyem Global, we understand the dynamic nature of the industrial landscape. Whether you are in the business of electronics, machinery, or any other sector, our tailored Industrial Services are geared to meet the evolving demands of your industry.</p>
                         </div>
                         {/* <div className="services__img mb-45 w-img">
                            <img src="assets/img/services/details/services-01.jpg" alt=""/>
                         </div> */}
                         <div className="services__text">
                            <h3>Key Offerings</h3>
                            <p>Explore the comprehensive range of Industrial Services offered by BIYEM GLOBAL SERVICES LTD, designed to optimize and enhance your manufacturing processes:</p>
                         </div>
                         <div className="services__list mb-40">
                        
                <ul>
                    <li>
                        <strong>Advanced Manufacturing:</strong> Our state-of-the-art manufacturing processes leverage the latest technologies, ensuring precision, speed, and scalability. We are dedicated to optimizing your production capabilities.
                    </li>
                    <li>
                        <strong>Packaging Solutions:</strong> BIYEM GLOBAL SERVICES LTD provides innovative and sustainable packaging solutions. Our team works closely with clients to design packaging that not only protects products but also enhances their market appeal.
                    </li>
                    <li>
                        <strong>Preservation Techniques:</strong> We employ cutting-edge preservation methods to extend the shelf life of goods, maintaining their quality and integrity. Our expertise ensures that your products reach customers in optimal condition.
                    </li>
                    <li>
                        <strong>Quality Assurance:</strong> Rigorous quality control measures are embedded in our processes. We uphold the highest standards to guarantee that each product meets and exceeds industry expectations.
                    </li>
                </ul>
                         </div>
                         <div className="services__text">
                            <h3>Benefits of Choosing BIYEM GLOBAL SERVICES LTD </h3>
                            <p>When you partner with BIYEM GLOBAL SERVICES LTD for your industrial needs, you're embracing a myriad of advantages tailored to elevate your operations:</p>
                         </div>
                         <div className="services__list mb-40">
                        
                        
                <ul>
                    <li>
                        <strong>Expertise:</strong> Our team consists of industry experts with a proven track record. We bring a wealth of experience and knowledge to address the unique challenges of your industrial operations.
                    </li>
                    <li>
                        <strong>Innovation:</strong> BIYEM GLOBAL SERVICES LTD is committed to staying at the forefront of technological advancements. We embrace innovation to offer solutions that not only meet current industry standards but also anticipate future trends.
                    </li>
                    <li>
                        <strong>Custom Solutions:</strong> Recognizing the diverse needs of our clients, we tailor our Industrial Services to provide customized solutions. Whether you are a large-scale manufacturer or a niche producer, we adapt our services to suit your requirements.
                    </li>
                    <li>
                        <strong>Reliability:</strong> Time is of the essence in industrial operations. BIYEM GLOBAL SERVICES LTD is known for its reliable and timely delivery. We understand the importance of keeping your production lines running smoothly.
                    </li>
                </ul>
                         </div>
                         {/* <div className="services__text">
                            <h4 >Why gormless loo he lost his bottle wellies cup of
                               tea pardon me lost the plot naff what a plonker lurgy show off show off pick your nose and blow.
                            </h4>
                            <p>Horse play skive off I amongst bonnet hanky panky
                               Richard brown bread grub I, sloshed nice one lavatory A bit of how's your father easy peasy daft
                               jolly good is, spiffing golly gosh in my flat cup of char tomfoolery a vagabond James Bond. Mush
                               down the pub victoria sponge zonked cracking goal off his nut the little rotter bits and bobs
                               say starkers blower why I william give us a bell.</p>
                         </div> */}
                      </div>
                   </div>
                </div>
          </section>
          <HomeTwoFooter />
        </>
    );
};

export default IndustrialServices;
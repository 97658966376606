import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const LogisticsCourierServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

            <HomeTwoHeader />
            <ServicesDetailsHeader
                title="Logistics & Courier Services"
                subtitle="Experience seamless supply chain management and reliable courier services with BIYEM GLOBAL. We ensure efficient transportation, secure deliveries, and optimized logistics solutions."
            />

            <section className="services__details pt-115 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="services__text">
                                <p>
                                    BIYEM GLOBAL brings unparalleled efficiency to your supply chain with our
                                    Logistics & Courier Services. We offer seamless transportation solutions,
                                    secure and timely deliveries, and optimized logistics management tailored to
                                    meet the demands of today's dynamic business environment.
                                </p>
                                <p>
                                    Whether you need reliable courier services or a comprehensive logistics partner,
                                    we are committed to delivering excellence at every step of the supply chain.
                                </p>
                            </div>

                            <div className="services__text">
                                <h3>Key Offerings</h3>
                                <p>
                                    Explore the comprehensive Logistics & Courier Services provided by BIYEM GLOBAL:
                                </p>
                            </div>
                            <div className="services__list mb-40">
                                <ul>
                                    <li>
                                        <strong>Freight Forwarding:</strong> Efficient handling of shipments with
                                        tailored solutions for air, sea, and land transportation.
                                    </li>
                                    <li>
                                        <strong>Secure Courier Services:</strong> Timely and secure deliveries for
                                        parcels, documents, and critical shipments.
                                    </li>
                                    <li>
                                        <strong>Warehousing and Distribution:</strong> Strategically located
                                        warehouses for optimized storage, inventory management, and distribution.
                                    </li>
                                    <li>
                                        <strong>Customs Clearance:</strong> Expert handling of customs procedures,
                                        ensuring smooth and compliant cross-border movements.
                                    </li>
                                    <li>
                                        <strong>Last-Mile Delivery:</strong> Efficient and reliable last-mile
                                        delivery services for a seamless customer experience.
                                    </li>
                                    <li>
                                        <strong>Supply Chain Optimization:</strong> Strategic planning and
                                        implementation to streamline and optimize your entire supply chain.
                                    </li>
                                </ul>
                            </div>

                            <div className="services__text">
                                <h3>Benefits of Choosing BIYEM GLOBAL</h3>
                                <p>
                                    Partnering with BIYEM GLOBAL SERVICES LTD for Logistics & Courier Services offers
                                    numerous advantages:
                                </p>
                            </div>
                            <div className="services__list mb-40">
                                <ul>
                                    <li>
                                        <strong>Reliability:</strong> Trust in our commitment to secure and timely
                                        deliveries, ensuring your goods reach their destination without
                                        complications.
                                    </li>
                                    <li>
                                        <strong>Global Network:</strong> Leverage our extensive network for
                                        international logistics and courier services, connecting you to markets
                                        worldwide.
                                    </li>
                                    <li>
                                        <strong>Technology Integration:</strong> Stay ahead with our technology-driven
                                        solutions, providing real-time visibility and transparency in your supply
                                        chain.
                                    </li>
                                    <li>
                                        <strong>Cost-Efficiency:</strong> Optimize costs through our tailored logistics
                                        solutions, reducing transportation and storage expenses.
                                    </li>
                                    <li>
                                        <strong>Customer Satisfaction:</strong> Enhance your customer experience with
                                        reliable deliveries, last-mile solutions, and efficient communication
                                        channels.
                                    </li>
                                    <li>
                                        <strong>Compliance Assurance:</strong> Navigate complex customs regulations
                                        with ease, ensuring compliance and avoiding delays.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HomeTwoFooter />
        </>
    );
};

export default LogisticsCourierServices;

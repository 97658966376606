import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const AgriculturalServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

<HomeTwoHeader />
<ServicesDetailsHeader title="Agricultural Service" subtitle="Cultivate success with BIYEM GLOBAL SERVICES LTD's comprehensive Agricultural Services. From supporting small-scale farmers to facilitating large-scale agricultural operations, we bring innovation and expertise to every facet of the agricultural sector." />
          <section className="services__details pt-115 pb-100">
             <div className="container">
                <div className="row">
                   {/* <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                      <div className="services__sidebar mr-50">
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Categories</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__link">
                                  <ul>
                                     <li><Link to="/servicesDetails">Market Analysis</Link></li>
                                     <li><Link to="/servicesDetails"> Great Tests</Link></li>
                                     <li><Link to="/servicesDetails">Optimal Choice</Link></li>
                                     <li><Link to="/servicesDetails">SEO Optimized</Link></li>
                                     <li><Link to="/servicesDetails">Task Consulting</Link></li>
                                     <li><Link to="/servicesDetails">Great Advices</Link></li>
                                  </ul>
                               </div>
                            </div>
                         </div>
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Subscribe Now</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__form">
                                  <form>
                                     <input type="text" placeholder="Name"/>
                                        <input type="email" placeholder="Email"/>
                                           <button className="z-btn z-btn-3 w-100">Subscribe Now</button>
                                        </form>
                                     </div>
                               </div>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <FiDownload/> </i>Download docs</a>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <AiOutlineFilePdf/> </i>Characteristics</a>
                            </div>
                         </div>
                      </div> */}
                      <div className="col-xl-12 col-lg-12">
                         <div className="services__text">
                         <p>At BIYEM GLOBAL SERVICES LTD, we understand the pivotal role of agriculture in sustaining communities and economies. Our Agricultural Services are designed to empower farmers, enhance agricultural productivity, and contribute to the growth of the agricultural sector.</p>
                <p>Whether you are a small-scale farmer looking for modern solutions or a large-scale agricultural enterprise seeking innovation, our services are tailored to meet your specific needs.</p>
             </div>
                         {/* <div className="services__img mb-45 w-img">
                            <img src="assets/img/services/details/services-01.jpg" alt=""/>
                         </div> */}
                         <div className="services__text">
                            <h3>Key Offerings</h3>
                            <p>Explore our comprehensive Agricultural Services designed to address the diverse needs of farmers and agricultural businesses:</p>
              </div>
                         <div className="services__list mb-40">
                         <ul>
                    <li>
                        <strong>Small-Scale Farming Support:</strong> Empower small-scale farmers with our support programs. We provide resources, training, and access to modern agricultural practices to enhance productivity and income.
                    </li>
                    <li>
                        <strong>Large-Scale Agricultural Operations:</strong> Facilitate large-scale agricultural enterprises with our expertise. From crop planning to machinery optimization, we offer solutions to improve efficiency and yield.
                    </li>
                    <li>
                        <strong>Tree Crop Productions:</strong> Support tree crop farmers with specialized services. Our expertise extends to the cultivation and management of tree crops, ensuring sustainable practices and high-quality yields.
                    </li>
                    <li>
                        <strong>Dairy Farms Management:</strong> Enhance the productivity of dairy farms with our management services. We cover everything from herd management to dairy product processing, ensuring a streamlined and profitable operation.
                    </li>
                    <li>
                        <strong>Manufacturing of Livestock Feeds:</strong> Support livestock farmers with our manufacturing services. We produce high-quality feeds tailored to the nutritional needs of various livestock, promoting healthy and productive animals.
                    </li>
                    <li>
                        <strong>Fertilizers and Farming Implements:</strong> Provide essential resources to farmers with our supply of fertilizers and farming implements. We ensure access to quality inputs for optimal agricultural practices.
                    </li>
                </ul>
                         </div>
                         <div className="services__text">
                         <h3>Benefits of Choosing BIYEM GLOBAL SERVICES LTD for Agricultural Services</h3>
                         <p>Partnering with BIYEM GLOBAL SERVICES LTD for agricultural services brings a host of benefits to farmers and agricultural enterprises:</p>
                </div>
                         <div className="services__list mb-40">
                        
                         <ul>
                    <li>
                        <strong>Empowering Small-Scale Farmers:</strong> Our support programs empower small-scale farmers with resources, training, and modern agricultural practices, improving their productivity and income.
                    </li>
                    <li>
                        <strong>Optimizing Large-Scale Operations:</strong> For large-scale agricultural enterprises, we offer solutions to optimize crop planning, machinery use, and overall efficiency, leading to increased yield and profitability.
                    </li>
                    <li>
                        <strong>Sustainable Tree Crop Cultivation:</strong> Our expertise in tree crop management ensures sustainable practices, promoting the health of the crops and the environment while maximizing yields.
                    </li>
                    <li>
                        <strong>Efficient Dairy Farms:</strong> Improve the efficiency of dairy farms with our management services, covering herd management, processing, and product quality control for a profitable operation.
                    </li>
                    <li>
                        <strong>Quality Livestock Feeds:</strong> Our manufacturing services provide farmers with high-quality livestock feeds, promoting the health and productivity of livestock for a thriving farming enterprise.
                    </li>
                    <li>
                        <strong>Access to Essential Inputs:</strong> Ensure farmers have access to quality fertilizers and farming implements, providing the necessary resources for successful agricultural practices.
                    </li>
                    <li>
                        <strong>Community Development:</strong> Beyond individual benefits, our Agricultural Services contribute to community development by supporting local farmers and fostering sustainable agricultural practices.
                    </li>
                </ul>
                         </div>
                         {/* <div className="services__text">
                            <h4 >Why gormless loo he lost his bottle wellies cup of
                               tea pardon me lost the plot naff what a plonker lurgy show off show off pick your nose and blow.
                            </h4>
                            <p>Horse play skive off I amongst bonnet hanky panky
                               Richard brown bread grub I, sloshed nice one lavatory A bit of how's your father easy peasy daft
                               jolly good is, spiffing golly gosh in my flat cup of char tomfoolery a vagabond James Bond. Mush
                               down the pub victoria sponge zonked cracking goal off his nut the little rotter bits and bobs
                               say starkers blower why I william give us a bell.</p>
                         </div> */}
                      </div>
                   </div>
                </div>
          </section>
          {/* <section className="service-contact">
                <h2>Get in Touch</h2>
                <p>Ready to embark on a transformative journey with BIYEM GLOBAL SERVICES LTD's ICT Services? Contact us today to schedule a consultation, and let's explore how our innovative solutions can drive your business forward into a digital future.</p>
                <Link to="/contact" className="cta-button">Contact Us</Link>
            </section> */}
          <HomeTwoFooter />
        </>
    );
};

export default AgriculturalServices;
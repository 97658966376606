import React from 'react';
import HomeThreeSecondSingleService from '../../../components/HomeThreeSecondSingleService/HomeThreeSecondSingleService';
import { SiKnowledgebase } from 'react-icons/si';
import { BsTools, BsHeart, BsBag, BsLightbulb } from 'react-icons/bs';
import { BiRocket } from 'react-icons/bi';
import { FaCogs } from 'react-icons/fa';
import { AiOutlineLineChart } from 'react-icons/ai';

const HomeThreeSecondServices = () => {
   return (
      <>
 <section className="services__area-3 pt-115 pb-160">
    <div className="container">
        <div className="row">
            <div className="col-xl-6">
                <div className="section-title section__title-3 mb-70">
                    <h2>Elevate Your Strategy with Our Consultancy Services</h2>
                    <p>We provide expert guidance to optimize your business strategy and navigate uncertainties.</p>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-xl-12 col-lg-12">
                <div className="services__nav wow fadeInUp" data-wow-delay=".4s">
                    <ul className="nav nav-pills " id="services-tab" role="tablist">
                        <li className="nav-item mb-30">
                            <a className="nav-link active" id="consultancy-tab" data-bs-toggle="pill" href="#consultancy" role="tab" aria-controls="consultancy" aria-selected="true">
                                <i> <BsLightbulb /> </i>Strategic Consultancy
                            </a>
                        </li>
                        <li className="nav-item mb-30">
                            <a className="nav-link" id="innovation-tab" data-bs-toggle="pill" href="#innovation" role="tab" aria-controls="innovation" aria-selected="true">
                                <i ><BiRocket /></i> Innovation Solutions
                            </a>
                        </li>
                        <li className="nav-item mb-30">
                            <a className="nav-link" id="transformation-tab" data-bs-toggle="pill" href="#transformation" role="tab" aria-controls="transformation" aria-selected="true">
                                <i ><FaCogs /></i> Business Transformation
                            </a>
                        </li>
                        <li className="nav-item mb-30">
                            <a className="nav-link" id="growth-tab" data-bs-toggle="pill" href="#growth" role="tab" aria-controls="growth" aria-selected="true">
                                <i ><AiOutlineLineChart /></i> Growth Strategies
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-12">
                <div className="services__tab grey-bg-18">
                    <div className="tab-content" id="services-content">
                        <div className="tab-pane fade show active" id="consultancy" role="tabpanel" aria-labelledby="consultancy-tab">
                            <HomeThreeSecondSingleService title="Strategic Consultancy"
                        
                            paragraph="Unlock the full potential of your business with our Strategic Consultancy services. We provide tailored guidance and innovative solutions to navigate challenges and achieve your long-term goals."
                            listtitle1="Acquisitions Finance Consulting"
                            listsubtitle1="Optimize your financial strategies through our expert advice on acquisitions and finance management."
                            listtitle2="Private Placement Consulting"
                            listsubtitle2="Explore strategic opportunities with our Private Placement Consulting, ensuring your business growth through well-informed decisions."
                            />
                    
                        </div>
                        <div className="tab-pane fade" id="innovation" role="tabpanel" aria-labelledby="innovation-tab">
                            <HomeThreeSecondSingleService title="Innovation Solutions" paragraph="Empower your business with cutting-edge strategies through our Innovation Solutions consultancy. We offer customized guidance and inventive approaches to overcome challenges and realize your long-term objectives."
                                  listtitle1="Innovative Financial Strategies"
                                  listsubtitle1="Elevate your financial game with our expertise in crafting innovative financial strategies, especially in the realms of acquisitions and finance management."
                                  listtitle2="Strategic Private Placement Advisory"
                                  listsubtitle2="Explore strategic opportunities with our Private Placement Consulting, ensuring your business growth through well-informed decisions."
                            />
                        </div>
                        <div className="tab-pane fade" id="transformation" role="tabpanel" aria-labelledby="transformation-tab">
                            <HomeThreeSecondSingleService title="Business Transformation" paragraph="Revitalize your business through our Business Transformation services." 
                                  listtitle1="Strategic Organizational Evolution"
                                  listsubtitle1="Reimagine and reshape your organization with our expert guidance on strategic organizational evolution. Streamline processes, foster innovation, and cultivate a culture of continuous improvement for enduring success."
                                  listtitle2="Digital Integration and Innovation"
                                  listsubtitle2="Ignite a digital revolution within your business. Our Business Transformation services encompass cutting-edge digital integration and innovation strategies, ensuring your company stays ahead in the rapidly evolving technological landscape."
                            />
                        </div>
                        <div className="tab-pane fade" id="growth" role="tabpanel" aria-labelledby="growth-tab">
                            <HomeThreeSecondSingleService title="Growth Strategies" paragraph="Unleash the full potential of your business with our comprehensive Growth Strategies." 
                                  listtitle1="Market Expansion Planning"
                                  listsubtitle1="Chart a path to success with strategic market expansion planning. Our experts analyze opportunities, mitigate risks, and tailor expansion strategies to ensure your business thrives in new territories."
                                  listtitle2="Product and Service Diversification"
                                  listsubtitle2="Fuel growth through diversification. Our Growth Strategies encompass innovative approaches to expand your product or service offerings, enabling you to capture new markets and meet evolving customer needs."
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

      </>
   );
};

export default HomeThreeSecondServices;
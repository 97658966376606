import React, { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import Lightbox from 'react-image-lightbox';
import { Link } from 'react-router-dom';

const SinglePortfolio = ({ portfolio, filterPortfolio, index }) => {
   const { img, title, subtitle } = portfolio;

   const [photoIndex, setPhotoIndex] = useState(index);
   const [open, setOpen] = useState(false);
   const lightboxImages = filterPortfolio.map(img => img.img);
   const images = lightboxImages

   return (
      <>

         {open && (
            <Lightbox

               mainSrc={images[photoIndex]}
               nextSrc={images[(photoIndex + 1) % images.length]}
               prevSrc={images[(photoIndex + images.length - 1) % images.length]}
               onCloseRequest={() => setOpen(false)}
               onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
               onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            />
         )}

         <div className={img === "assets/img/portfolio/port-1.jpg" ? "col-xl-4 col-lg-4 col-md-4 col-sm-12" :
            "col-xl-4 col-lg-4 col-md-6 col-sm-6"}>
            <div className="portfolio__item p-relative mb-30">
               {/* <div className="portfolio__thumb w-img fix">
                  <img style={{width:"150px",height:"100px"}} src={img} alt="" />
                  {/* <div onClick={setOpen} className="portfolio__plus p-absolute transition-3">
                     <button onClick={() => setPhotoIndex(index)}>
                        <i > <AiOutlinePlus /> </i>
                        <i > <AiOutlinePlus /> </i>
                     </button>
                  </div> /}
               </div> */}
               <div className="col-md-12">
<div  className="card mb-4 shadow-sm position-relative ">
<div className="portfolio-img-list text-center">
<img width="100%" height="150" alt="logo" src={img} />
</div>

<div class="card-body">
<h3 >{title}</h3>
<hr style={{color:"grey"}}/>
{/* <p class="card-text">
{subtitle}</p> */}
{
   subtitle.map((sub) => (
      <div className="about__list">
      <ul>
         <li ><span><i > <FaCheck/> </i>{sub}</span></li>
        
      </ul>
   </div>
   ))
}
{/* <div class="d-flex justify-content-between align-items-center">
<button class="btn_readmore_round_medium" onclick="window.location.href='https://www.2basetechnologies.com/portfolios/dubai-insurance-brokers'">Read more<i class="fas fa-arrow-right"></i></button>
</div> */}
</div>
</div>
</div>
               {/* <div className="portfolio__content">
                  <h4><Link to="/portfolioDetails">{title}</Link></h4>
                  <p>{subtitle}</p>
                  <div className="portfolio__more p-absolute transition-3">
                     <Link to="/portfolioDetails" className="link-btn-2">
                        Show Project
                        <i > <CgArrowLongRight /> </i>
                        <i > <CgArrowLongRight /> </i>
                     </Link>
                  </div>
               </div> */}
            </div>
         </div>
      </>
   );
};

export default SinglePortfolio;
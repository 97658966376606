import React from 'react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PageHelmet from '../shared/PageHelmet';
import HomeTwoHeader from '../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader';
import ServicesDetailsHeader from '../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader';
import HomeTwoFooter from '../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter';

const ProcurementServices = () => {
    return (
        <>
            <PageHelmet pageTitle="Services Details Page" />

<HomeTwoHeader />
<ServicesDetailsHeader title="Procurement Service" subtitle="Streamline your supply chain with BIYEM GLOBAL SERVICES LTD's comprehensive Procurement Services. From sourcing quality products to optimizing logistics, we are your strategic partner in ensuring a seamless and efficient procurement process." />
          <section className="services__details pt-115 pb-100">
             <div className="container">
                <div className="row">
                   {/* <div className="col-xl-4 col-lg-4 order-last order-lg-first">
                      <div className="services__sidebar mr-50">
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Categories</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__link">
                                  <ul>
                                     <li><Link to="/servicesDetails">Market Analysis</Link></li>
                                     <li><Link to="/servicesDetails"> Great Tests</Link></li>
                                     <li><Link to="/servicesDetails">Optimal Choice</Link></li>
                                     <li><Link to="/servicesDetails">SEO Optimized</Link></li>
                                     <li><Link to="/servicesDetails">Task Consulting</Link></li>
                                     <li><Link to="/servicesDetails">Great Advices</Link></li>
                                  </ul>
                               </div>
                            </div>
                         </div>
                         <div className="services__widget grey-bg-18 mb-40">
                            <div className="services__widget-title">
                               <h4>Subscribe Now</h4>
                            </div>
                            <div className="services__widget-content">
                               <div className="services__form">
                                  <form>
                                     <input type="text" placeholder="Name"/>
                                        <input type="email" placeholder="Email"/>
                                           <button className="z-btn z-btn-3 w-100">Subscribe Now</button>
                                        </form>
                                     </div>
                               </div>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <FiDownload/> </i>Download docs</a>
                            </div>
                            <div className="services__action grey-bg-18 mb-15">
                            <a href="#"><i > <AiOutlineFilePdf/> </i>Characteristics</a>
                            </div>
                         </div>
                      </div> */}
                      <div className="col-xl-12 col-lg-12">
                         <div className="services__text">
                         <p>At BIYEM GLOBAL SERVICES LTD, we recognize the critical role of efficient procurement in the success of businesses. Our Procurement Services are designed to provide end-to-end solutions, from identifying reliable suppliers to optimizing the logistics of product delivery.</p>
                <p>Whether you are a small business looking to enhance your procurement efficiency or a large corporation aiming for cost savings, our services are tailored to meet your specific procurement needs.</p>
           </div>
                         {/* <div className="services__img mb-45 w-img">
                            <img src="assets/img/services/details/services-01.jpg" alt=""/>
                         </div> */}
                         <div className="services__text">
                            <h3>Key Offerings</h3>
                            <p>Explore our comprehensive Procurement Services designed to streamline your supply chain and enhance operational efficiency:</p>
              </div>
                         <div className="services__list mb-40">
                         <ul>
                    <li>
                        <strong>Supplier Sourcing:</strong> Identify and partner with reliable suppliers globally. Our procurement experts conduct thorough assessments to ensure the quality, reliability, and ethical standards of suppliers.
                    </li>
                    <li>
                        <strong>Logistics Optimization:</strong> Enhance the efficiency of product delivery with our logistics optimization services. We analyze and streamline the transportation and distribution processes for cost-effective and timely deliveries.
                    </li>
                    <li>
                        <strong>Cost Negotiation:</strong> Maximize cost savings through strategic negotiation with suppliers. Our procurement team is skilled in securing favorable terms while maintaining the highest quality standards.
                    </li>
                    <li>
                        <strong>Procurement Analytics:</strong> Leverage data-driven insights for informed decision-making. Our analytics services provide valuable information on procurement trends, supplier performance, and cost-effectiveness.
                    </li>
                </ul>
                         </div>
                         <div className="services__text">
                         <h3>Benefits of Choosing BIYEM GLOBAL SERVICES LTD for Procurement Services</h3>
                         <p>Partnering with BIYEM GLOBAL SERVICES LTD for procurement services brings a range of strategic advantages to your business:</p>
                </div>
                         <div className="services__list mb-40">
                        
                         <ul>
                    <li>
                        <strong>Reliable Supplier Network:</strong> Access a reliable and ethical network of suppliers globally, ensuring the quality and consistency of products for your business.
                    </li>
                    <li>
                        <strong>Streamlined Logistics:</strong> Improve the efficiency of product delivery with our logistics optimization services, reducing lead times and minimizing transportation costs.
                    </li>
                    <li>
                        <strong>Cost Savings:</strong> Maximize cost savings through strategic negotiation with suppliers, ensuring that you receive the best value for your procurement investments.
                    </li>
                    <li>
                        <strong>Data-Driven Decision-Making:</strong> Make informed decisions with our procurement analytics services, utilizing data insights to optimize procurement strategies and enhance overall efficiency.
                    </li>
                    <li>
                        <strong>Scalable Solutions:</strong> Our Procurement Services are scalable to meet the needs of businesses of all sizes, from small enterprises to large corporations, providing tailored solutions for sustainable growth.
                    </li>
                    <li>
                        <strong>Operational Efficiency:</strong> Enhance the overall efficiency of your procurement process, allowing your business to focus on core competencies while we handle the complexities of supplier management and logistics.
                    </li>
                </ul>
                         </div>
                         {/* <div className="services__text">
                            <h4 >Why gormless loo he lost his bottle wellies cup of
                               tea pardon me lost the plot naff what a plonker lurgy show off show off pick your nose and blow.
                            </h4>
                            <p>Horse play skive off I amongst bonnet hanky panky
                               Richard brown bread grub I, sloshed nice one lavatory A bit of how's your father easy peasy daft
                               jolly good is, spiffing golly gosh in my flat cup of char tomfoolery a vagabond James Bond. Mush
                               down the pub victoria sponge zonked cracking goal off his nut the little rotter bits and bobs
                               say starkers blower why I william give us a bell.</p>
                         </div> */}
                      </div>
                   </div>
                </div>
          </section>
          {/* <section className="service-contact">
                <h2>Get in Touch</h2>
                <p>Ready to embark on a transformative journey with BIYEM GLOBAL SERVICES LTD's ICT Services? Contact us today to schedule a consultation, and let's explore how our innovative solutions can drive your business forward into a digital future.</p>
                <Link to="/contact" className="cta-button">Contact Us</Link>
            </section> */}
          <HomeTwoFooter />
        </>
    );
};

export default ProcurementServices;
const portfolioData = [
    {
        id: 1,
        img: 'assets/img/brand/fgnpclogo1.png',
        title: 'FGN Power Company',
        subtitle: ['Development of Official Website', 'Annual Website Maintenance'],
        category: 'ICT',
    },
    {
        id: 2,
        img: 'assets/img/brand/newnpc.png',
        title: 'National Population Commission',
        subtitle: ['Supply of OTP/SMS for Census', 'Supply of Branded Reflective Jacket'],
        category: 'Procurement',
    },
    {
        id: 3,
        img: 'assets/img/brand/nucv2.png',
        title: 'National University Commission (NUC)',
        subtitle: ['Supply of Diesel'],
        category: 'Procurement',
    },

    {
        id: 4,
        img: 'assets/img/brand/edulogo1.png',
        title: 'Federal Ministry of Education',
        subtitle: ['Supply and installation of solar light to class rooms at FGGC, Abaji ','Procurement of Furnitures & fittings at federal science & technical college, Nsarawa state. '],
        category: 'Procurement',
    },
    {
        id: 5,
        img: 'assets/img/brand/nscdc.jpeg',
        title: 'NIGERIA SECURITY AND CIVIL DEFENCE CORPS (NSCDC)',
        subtitle: ['Supply of foldable camp beds',],
        category: 'Procurement',
    },


];

export default portfolioData;

import React from "react";
import { AiOutlineFilePdf } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";

import HomeTwoHeader from "../../pages/HomeTwo/HomeTwoHeader/HomeTwoHeader";
import ServicesDetailsHeader from "../../pages/ServicesDetails/ServicesDetailsHeader/ServicesDetailsHeader";
import HomeTwoFooter from "../../pages/HomeTwo/HomeTwoFooter/HomeTwoFooter";
import PageHelmet from "../../components/shared/PageHelmet";
import { FaCheck } from "react-icons/fa";

const Careers = () => {
  return (
    <>
      <PageHelmet pageTitle="Careers" />

      <HomeTwoHeader />
      <ServicesDetailsHeader
        title="Careers at BIYEM GLOBAL SERVICES LTD"
        subtitle="Join a team that values innovation, collaboration, and making a positive impact. Explore exciting career opportunities at BIYEM GLOBAL SERVICES LTD and become part of a dynamic and diverse workplace."
      />
      <section className="achievement__area pt-135 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-6">
              <div className="achievement__content">
                <div className="section__title section__title-3 mb-20">
                  <span style={{fontSize:"34px"}}>Why we are a</span>
                  <h2>Great Place to Work?</h2>
                </div>
                <p>
                At BIYEM GLOBAL SERVICES LTD, we offer more than just a job. Here are some reasons to consider joining our team:
                </p>
                <div
                  className="achievement__wrapper d-flex d-md-block d-lg-flex justify-content-between mb-35 wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <div className="about__list">
                           <ul>
                              <li ><span><i > <FaCheck/> </i> Challenging and Impactful Work </span></li>
                              <li ><span><i > <FaCheck/> </i>Collaborative Environment</span></li>
                              <li ><span><i > <FaCheck/> </i>Professional Development</span></li>
                              <li ><span><i > <FaCheck/> </i>Diverse and Inclusive Culture</span></li>
                              <li ><span><i > <FaCheck/> </i>Work-Life Balance</span></li>
                              <li ><span><i > <FaCheck/> </i>Commitment to Sustainability</span></li>
                           </ul>
                        </div>
                </div>
                <Link to="/about" className="z-btn">
                  Learn More
                </Link>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 col-md-6">
              <div className="row">
                <div className="col-xl-7 col-sm-6">
                  <div className="achievement__thumb m-img pl-30 text-right">
                    <img
                      style={{ borderRadius: "40px" }}
                      src="assets/img/achievement/achievement-3.jpeg"
                      alt="achievement-1"
                    />
                  </div>
                </div>
                <div className="col-xl-5 col-sm-6 d-md-none d-lg-none d-xl-block">
                  <div className="achievement__thumb w-img">
                    <img
                      style={{ borderRadius: "40px" }}
                      src="assets/img/achievement/achievement-6.jpeg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="what-we-offer p-5">
<div class="container">
<h3 class="mb-30">What we offer</h3>
<div class="row gx-5">
<div class="col-lg-4 card mb-15 shadow-sm pt-25 ">
<div class="  text-center ">
<img style={{width:"268px", height:"268px"}} alt=""  src="assets/img/icon/brand/health.png"/>
<h5>Health Benefits</h5>
</div>
</div>
<div class="col-lg-4 col-lg-4 card mb-5 shadow-sm pt-25 ">
<div class="what-we-offer-card text-center ">
<img style={{width:"268px", height:"268px"}} alt=""  class=" lazyloaded" src="assets/img/icon/brand/fun.png"/>
<h5>Fun and Recreations</h5>
</div>
</div>
<div class="col-lg-4 col-lg-4 card mb-5 shadow-sm pt-25 ">
<div class="what-we-offer-card text-center ">
<img style={{width:"268px", height:"250px"}} alt="" src="assets/img/icon/brand/leave.png"/>
<h5>Leave Entitlements</h5>
</div>
</div>
<div class="col-lg-4 col-lg-4 card mb-5 shadow-sm pt-25 ">
<div class="what-we-offer-card text-center ">
<img style={{width:"268px", height:"268px"}} alt=""  src="assets/img/icon/brand/reward.png"/>
<h5>Rewards And Recognition</h5>
</div>
</div>
<div class="col-lg-4 col-lg-4 card mb-5 shadow-sm pt-25 ">
<div class="what-we-offer-card text-center ">
<img style={{width:"268px", height:"268px"}} alt=""   src="assets/img/icon/brand/outing.png"/>
<h5>Team Outings</h5>
</div>
</div>
<div class="col-lg-4 col-lg-4 card mb-5 shadow-sm pt-25 ">
<div class="what-we-offer-card text-center ">
<img style={{width:"268px", height:"250px"}}  alt=""   src="assets/img/icon/brand/refer.png"/>
<h5>Refferal Programs</h5>
</div>
</div>
<div class="col-lg-4 col-lg-4 card mb-5 shadow-sm pt-25 ">
<div class="what-we-offer-card text-center ">
<img style={{width:"268px", height:"268px"}} alt=""  class=" ls-is-cached lazyloaded" src="assets/img/icon/brand/training.png"/>
<h5>Trainings</h5>
</div>
</div>
<div class="col-lg-4 col-lg-4 card mb-5 shadow-sm pt-25 ">
<div class="what-we-offer-card text-center ">
<img style={{width:"268px", height:"268px"}} alt=""  src="assets/img/icon/brand/pantry.png"/>
<h5>Dedicated Pantry</h5>
</div>
</div>
<div class="col-lg-4 col-lg-4 card mb-5 shadow-sm pt-25 ">
<div class="what-we-offer-card text-center ">
<img style={{width:"268px", height:"228px"}} alt=""  src="assets/img/icon/brand/workplace.png"/>
<h5>Workplace Flexibility</h5>
</div>
</div>
</div>
</div>
</section>
      <HomeTwoFooter />
    </>
  );
};

export default Careers;
